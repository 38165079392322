var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "q-pull-to-refresh",
            {
              style:
                _vm.kioskMode === true
                  ? "margin-bottom: -100px; overflow: hidden;"
                  : "overflow: hidden;",
              attrs: {
                handler: _vm.channelInit,
                "refresh-icon": "ion-sync",
                "release-message": _vm.$t("PTR.RELEASE"),
                "refresh-message": _vm.$t("PTR.REFRESH"),
                "pull-message": _vm.$t("PTR.PULL"),
                color: "faded"
              }
            },
            [
              _c(
                "div",
                { staticClass: "product column items-center" },
                [
                  _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
                  _c(
                    "q-card",
                    { staticClass: "q-card-flat text-center" },
                    [
                      _vm.kioskMode === false
                        ? _c(
                            "q-card-media",
                            {
                              staticClass:
                                "q-card-flat-photo q-card-media-gallery",
                              class: {
                                "q-card-media-gallery-expand": _vm.galleryShow
                              },
                              style: _vm.showImages
                                ? "background-image: url(" +
                                  _vm.getProductPhotoResource(
                                    _vm.product.data.media.photos.resources,
                                    _vm.galleryShowIndex
                                  ) +
                                  ")"
                                : "min-height: 0; max-height: 0; height: 0;",
                              nativeOn: {
                                click: function($event) {
                                  _vm.galleryShow = _vm.getProductPhotoResource(
                                    _vm.product.data.media.photos.resources
                                  )
                                }
                              }
                            },
                            [
                              !_vm.getProductPhotoResource(
                                _vm.product.data.media.photos.resources
                              )
                                ? _c("img", {
                                    staticClass: "no-photos",
                                    staticStyle: {
                                      height: "111px",
                                      "background-color": "#9B9B9B"
                                    },
                                    attrs: {
                                      src:
                                        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "q-carousel",
                                {
                                  staticClass:
                                    "full-width q-card-media-gallery-carousel",
                                  class: {
                                    "q-card-media-gallery-carousel-min": !_vm.galleryShow,
                                    "q-card-media-gallery-carousel-max":
                                      _vm.galleryShow
                                  },
                                  attrs: {
                                    color: "white",
                                    infinite: "",
                                    "thumbnails-icon": "ion-images",
                                    arrows: "",
                                    autoplay: !_vm.galleryShow,
                                    "handle-arrow-keys": ""
                                  },
                                  on: { slide: _vm.galleryShowEventSlide },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "control-button",
                                        fn: function(carousel) {
                                          return _c(
                                            "q-carousel-control",
                                            {
                                              attrs: {
                                                position: "top-right",
                                                offset: [18, 22]
                                              }
                                            },
                                            [
                                              _c("q-btn", {
                                                attrs: {
                                                  round: "",
                                                  dense: "",
                                                  color: "tertiary",
                                                  icon: "ion-close"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    _vm.galleryShow = false
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      },
                                      {
                                        key: "control-progress",
                                        fn: function(carousel) {
                                          return _c(
                                            "q-carousel-control",
                                            {
                                              attrs: {
                                                position: "bottom",
                                                offset: [0, 0]
                                              }
                                            },
                                            [
                                              _c("q-progress", {
                                                attrs: {
                                                  percentage:
                                                    carousel.percentage,
                                                  color: "primary"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2196596314
                                  )
                                },
                                _vm._l(
                                  _vm.product.data.media.photos.resources,
                                  function(r, i) {
                                    return _c("q-carousel-slide", {
                                      key: "q-c-" + i,
                                      attrs: {
                                        "img-src": _vm.getProductPhotoResource(
                                          _vm.product.data.media.photos
                                            .resources,
                                          i
                                        )
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        : _c("q-card-media", {
                            staticStyle: {
                              "margin-top": "100px",
                              "margin-bottom": "-40px"
                            }
                          }),
                      _c("q-card-title", [
                        _vm.product.data.media.logo
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                height: "75px",
                                border: "2px solid black",
                                "border-radius": "66px",
                                overflow: "hidden",
                                "background-color": "white",
                                padding: "2px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              style: {
                                width:
                                  _vm.product.data.media.logo.ratio === "wide"
                                    ? "120px"
                                    : "75px",
                                "border-radius":
                                  _vm.product.data.media.logo.shape === "square"
                                    ? "4px"
                                    : "66px"
                              },
                              attrs: { src: _vm.product.data.media.logo.url }
                            })
                          : _vm._e(),
                        _vm.productName &&
                        _vm.productName === "Avalon Marlborough"
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                width: "75px",
                                height: "75px",
                                border: "2px solid black",
                                "border-radius": "4px",
                                overflow: "hidden",
                                "background-color": "black",
                                padding: "4px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              attrs: {
                                src:
                                  "https://www.avaloncommunities.com/pf/resources/img/brand-logos/avalon.svg?d=79"
                              }
                            })
                          : _vm._e(),
                        _vm.productName &&
                        _vm.productName === "Zaroob Motor City"
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                width: "75px",
                                height: "75px",
                                border: "2px solid black",
                                "border-radius": "4px",
                                overflow: "hidden",
                                "background-color": "white",
                                padding: "4px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              attrs: {
                                src:
                                  "https://eatopi-content.kitopiconnect.com/images/logos/3ed2d6db-b6bd-47bd-9607-a8b7fbc1c009/header/9xtonkii3qf"
                              }
                            })
                          : _vm._e(),
                        _vm.productName &&
                        _vm.productName === "Talia Apartments"
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                width: "75px",
                                height: "75px",
                                border: "2px solid black",
                                "border-radius": "4px",
                                overflow: "hidden",
                                "background-color": "black",
                                padding: "4px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              attrs: {
                                src:
                                  "https://www.talia-apts.com/uploads/properties/logos/639x639G/17338/white-logo_talia.png?1602865205"
                              }
                            })
                          : _vm._e(),
                        _vm.productName && _vm.productName === "Starbucks"
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                width: "75px",
                                height: "75px",
                                border: "2px solid black",
                                "border-radius": "66px",
                                overflow: "hidden",
                                "background-color": "white",
                                padding: "2px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              attrs: {
                                src:
                                  "https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1920px-Starbucks_Corporation_Logo_2011.svg.png"
                              }
                            })
                          : _vm._e(),
                        _vm.productName &&
                        _vm.productName === "Allo Beirut - Hessa Street"
                          ? _c("img", {
                              staticClass: "block",
                              staticStyle: {
                                width: "75px",
                                height: "75px",
                                border: "2px solid transparent",
                                "border-radius": "4px",
                                overflow: "hidden",
                                "background-color": "white",
                                padding: "2px",
                                margin: "-75px auto 5px",
                                position: "relative",
                                "box-shadow":
                                  "0 0 1px 3px rgb(255 255 255 / 60%)"
                              },
                              attrs: {
                                src:
                                  "https://cdn-gdhcp.nitrocdn.com/YdLvFIYNyGMsMgiMTKYStcgbqCdztZVD/assets/images/optimized/rev-d9c8d70/wp-content/uploads/2020/12/logo2.png"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-size-140p text-weight-semibold inline-block",
                            class: { hidden: _vm.productNameHidden },
                            staticStyle: { "line-height": "1.1em" }
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.productName) + " "
                            ),
                            _vm.product.verified
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      "/statics/_demo/checkmark.fill.seal_wing.svg",
                                    width: "22",
                                    alt: _vm.$t(
                                      "VERIFIED_" + _vm.product.verified_status
                                    ),
                                    title: _vm.$t(
                                      "VERIFIED_" + _vm.product.verified_status
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        !_vm.isVenue()
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "block text-family-brand text-subinfo-l capitalize font-size-90p",
                                staticStyle: { "margin-top": "-4px" }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "text-weight-semibold uppercase inline-block ellipsis text-system-brand",
                                  staticStyle: {
                                    "line-height": "1em",
                                    width: "90vw",
                                    "font-size": "0.8em"
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.product.data.business.address.html
                                    )
                                  }
                                })
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "block text-system-brand font-size-100p text-weight-bold uppercase"
                              },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "shallower",
                                      "text-color": "core",
                                      avatar: _vm.venue.logo
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.venue.name))]
                                )
                              ],
                              1
                            )
                      ])
                    ],
                    1
                  ),
                  _vm.contentInfoShow
                    ? _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated zoomIn animated-d400",
                            "leave-active-class":
                              "animated zoomOut animated-d200"
                          }
                        },
                        [
                          _vm.kioskMode === false
                            ? _c(
                                "div",
                                {
                                  key: "info",
                                  staticClass:
                                    "row no-wrap justify-center items-center relative-position",
                                  staticStyle: {
                                    "max-width": "400px",
                                    height: "70px",
                                    "margin-top": "-20px",
                                    "margin-bottom": "-10px"
                                  }
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      class: {
                                        "bg-primary":
                                          _vm.account.subscribed !== null &&
                                          _vm.account.subscribed,
                                        "bg-tertiary":
                                          !_vm.account ||
                                          _vm.account.isLoggedIn === false ||
                                          _vm.account.subscriptionProcess ||
                                          (_vm.account.subscribed !== null &&
                                            !_vm.account.subscribed)
                                      },
                                      staticStyle: {
                                        margin: "0px 10px",
                                        "min-width": "68px"
                                      },
                                      attrs: {
                                        loading:
                                          (_vm.accountInitProcessing ||
                                            _vm.account.isLoggedIn !== false) &&
                                          (!_vm.account ||
                                            _vm.account.subscriptionProcess ||
                                            _vm.account.subscribed === null),
                                        color: "white",
                                        size: "1em",
                                        flat: "",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.account.subscribed !== null &&
                                          _vm.account.subscribed
                                            ? _vm.unsubscribe()
                                            : _vm.subscribe()
                                        }
                                      }
                                    },
                                    [
                                      _vm.account.subscribed !== null &&
                                      _vm.account.subscribed
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                                "margin-top": "5px",
                                                "margin-left": "2px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  "margin-right": "4px"
                                                },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart.subscribed_white.svg",
                                                  width: "30px"
                                                }
                                              })
                                            ]
                                          )
                                        : _vm.account.subscribed !== null
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                                "margin-top": "5px",
                                                "margin-left": "2px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  "margin-right": "4px"
                                                },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart.subscribe_white.svg",
                                                  width: "30px"
                                                }
                                              })
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "nowrap",
                                                "margin-top": "5px",
                                                "margin-left": "2px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  "margin-right": "4px"
                                                },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart.subscribe_white.svg",
                                                  width: "30px"
                                                }
                                              })
                                            ]
                                          )
                                    ]
                                  ),
                                  _c(
                                    "q-btn",
                                    {
                                      staticStyle: { margin: "0px 5px" },
                                      attrs: { size: "1.4em", rounded: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.about($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { filter: "invert(.5)" },
                                        attrs: {
                                          src:
                                            "/statics/_demo/info_primary.svg",
                                          width: "34px"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm.product &&
                                  _vm.product.data &&
                                  _vm.product.data.business.g_url
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticStyle: { margin: "0px 5px" },
                                          attrs: { size: "1.4em", rounded: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openURL(
                                                _vm.product.data.business.g_url
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              filter: "invert(.5)"
                                            },
                                            attrs: {
                                              src:
                                                "/statics/_demo/map.fill.svg",
                                              width: "34px"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.product &&
                                  _vm.product.data &&
                                  _vm.product.data.qrcode_ref
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticStyle: { margin: "0px 5px" },
                                          attrs: { size: "1.4em", rounded: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.qrcode($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              filter: "invert(.5)"
                                            },
                                            attrs: {
                                              src:
                                                "/statics/_demo/qrcode_primary.svg",
                                              width: "34px"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.shareSheetSupport()
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticStyle: { margin: "0px 5px" },
                                          attrs: { size: "1.4em", rounded: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.shareSheet($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              filter: "invert(.5)"
                                            },
                                            attrs: {
                                              src:
                                                "/statics/_demo/square.and.arrow.up.fill_primary.svg",
                                              width: "28px"
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default",
                      attrs: { inline: "" }
                    },
                    [
                      _c("q-card-title", { staticClass: "no-padding" }, [
                        _c(
                          "div",
                          { staticClass: "row justify-start items-center" },
                          [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  mode: "out-in",
                                  appear: "",
                                  "enter-active-class":
                                    "animated slideInUp animated-d400",
                                  "leave-active-class":
                                    "animated slideOutDown animated-d200"
                                }
                              },
                              [
                                _vm.channel &&
                                _vm.channel.orders !== 0 &&
                                (_vm.product.data.business.hoo[_vm.today_day]
                                  .is24 ||
                                  (_vm.today_time_elapsed >= 0 &&
                                    _vm.today_time_elapsed <= 100) ||
                                  (_vm.today_time_elapsed < 0 &&
                                    _vm.today_time_elapsed > -10))
                                  ? _c(
                                      "div",
                                      {
                                        key: "brb",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Change in Operations\n          "
                                        )
                                      ]
                                    )
                                  : _vm.channel && _vm.channel.online === 0
                                  ? _c(
                                      "div",
                                      {
                                        key: "off",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Offline\n          "
                                        )
                                      ]
                                    )
                                  : _vm.channel &&
                                    !_vm.product.data.business.hoo[
                                      _vm.today_day
                                    ].isClosed &&
                                    (_vm.product.data.business.hoo[
                                      _vm.today_day
                                    ].is24 ||
                                      (_vm.today_time_elapsed >= 0 &&
                                        _vm.today_time_elapsed <= 100))
                                  ? _c(
                                      "div",
                                      {
                                        key: "good",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-educate"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Ready\n          "
                                        )
                                      ]
                                    )
                                  : _vm.today_time_elapsed < 0 &&
                                    _vm.today_time_elapsed > -10
                                  ? _c(
                                      "div",
                                      {
                                        key: "prepping",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-attention"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Getting Ready\n          "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        key: "others",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-shallow"
                                      },
                                      [_vm._v("\n            Zzz\n          ")]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    {
                      attrs: {
                        appear: "",
                        "enter-active-class": "animated fadeInUp animated-d800",
                        "leave-active-class": "animated fadeOutUp animated-d800"
                      }
                    },
                    [
                      _c(
                        "q-card",
                        {
                          key: "guardian",
                          ref: "product-card-guardian",
                          staticClass:
                            "no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget",
                          class: {
                            "q-card-widget-guard":
                              !_vm.channel ||
                              (_vm.channel &&
                                !_vm.channel.online &&
                                _vm.channel.orders === 0) ||
                              (_vm.today_time_elapsed < 0 ||
                                _vm.today_time_elapsed > 100) ||
                              _vm.product.data.business.hoo[_vm.today_day]
                                .isClosed,
                            "q-card-widget-guard-online":
                              _vm.channel &&
                              _vm.channel.online &&
                              _vm.channel.orders === 0 &&
                              !_vm.product.data.business.hoo[_vm.today_day]
                                .isClosed &&
                              (_vm.product.data.business.hoo[_vm.today_day]
                                .is24 ||
                                (_vm.today_time_elapsed >= 0 &&
                                  _vm.today_time_elapsed < 90)),
                            "q-card-widget-guard-warning":
                              (_vm.channel &&
                                (_vm.channel.orders !== 0 &&
                                  !(
                                    _vm.product.data.business.hoo[_vm.today_day]
                                      .isClosed ||
                                    (_vm.today_time_elapsed < 0 ||
                                      _vm.today_time_elapsed > 100)
                                  ))) ||
                              (_vm.channel &&
                                _vm.channel.online &&
                                !_vm.product.data.business.hoo[_vm.today_day]
                                  .is24 &&
                                !_vm.product.data.business.hoo[_vm.today_day]
                                  .isClosed &&
                                ((_vm.today_time_elapsed >= 90 &&
                                  _vm.today_time_elapsed <= 100) ||
                                  (_vm.today_time_elapsed < 0 &&
                                    _vm.today_time_elapsed > -10)))
                          },
                          attrs: { inline: "" }
                        },
                        [
                          _vm.today_day
                            ? [
                                _vm.channel === null
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        stripe: "",
                                        indeterminate: "",
                                        color: "hint",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.channel === false
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        stripe: "",
                                        percentage: 100,
                                        color: "hint",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.channel.online === 0 ||
                                    _vm.channel.orders !== 0
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        percentage: 100,
                                        stripe: "",
                                        color: "protect",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.product.data.business.hoo[_vm.today_day]
                                      .is24
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        stripe: "",
                                        indeterminate: "",
                                        color: "educate",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.product.data.business.hoo[_vm.today_day]
                                      .isClosed
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        percentage: 100,
                                        stripe: "",
                                        color: "protect",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.today_time_elapsed >= 0 &&
                                    _vm.today_time_elapsed < 100
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        stripe: "",
                                        indeterminate: "",
                                        color:
                                          _vm.today_time_elapsed >= 90
                                            ? "attention"
                                            : "green-d",
                                        height: "8px"
                                      }
                                    })
                                  : _vm.today_time_elapsed < 0 &&
                                    _vm.today_time_elapsed > -10
                                  ? _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        stripe: "",
                                        indeterminate: "",
                                        color: "attention",
                                        height: "8px"
                                      }
                                    })
                                  : _c("q-progress", {
                                      staticStyle: { "margin-bottom": "-8px" },
                                      attrs: {
                                        percentage: 100,
                                        stripe: "",
                                        color: "protect",
                                        height: "8px"
                                      }
                                    })
                              ]
                            : _vm._e(),
                          _c(
                            "q-card-title",
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    mode: "out-in",
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeIn animated-d400",
                                    "leave-active-class":
                                      "animated fadeOut animated-d200"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      key: _vm.channel
                                        ? "guardian-info-img-" +
                                          _vm.channel.online +
                                          "-" +
                                          _vm.channel.orders
                                        : "guardian-info-img-null",
                                      staticClass:
                                        "row justify-start items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "left on-left-lg items-center row text-center justify-center",
                                          staticStyle: {
                                            width: "55px",
                                            height: "70px"
                                          }
                                        },
                                        [
                                          _vm.channel === false
                                            ? _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-brighten-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/circle.b3line_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : !_vm.channel
                                            ? _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-brighten-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/circles4_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel.orders !== 0 ||
                                              (_vm.channel &&
                                                _vm.channel.online &&
                                                !_vm.product.data.business.hoo[
                                                  _vm.today_day
                                                ].isClosed &&
                                                (_vm.today_time_elapsed >= 90 &&
                                                  _vm.today_time_elapsed <=
                                                    100))
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/exclamationmark_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel &&
                                              _vm.channel.online &&
                                              !_vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].isClosed &&
                                              (_vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].is24 ||
                                                (_vm.today_time_elapsed >= 0 &&
                                                  _vm.today_time_elapsed < 90))
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel &&
                                              _vm.channel.online &&
                                              (_vm.today_time_elapsed < 0 &&
                                                _vm.today_time_elapsed > -10)
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel.online === 0 &&
                                              (_vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].is24 ||
                                                (_vm.today_time_elapsed >= 0 &&
                                                  _vm.today_time_elapsed <=
                                                    100))
                                            ? _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-img-brighten-50 dark-img-invert-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/exclamationmark_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-img-brighten-50 dark-img-invert-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/xmark_white.svg",
                                                  height: "48"
                                                }
                                              })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          key: _vm.channel
                                            ? "guardian-info-text-" +
                                              _vm.channel.online +
                                              "-" +
                                              _vm.channel.orders
                                            : "guardian-info-text-null",
                                          staticClass:
                                            "float-left line-height-sm"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-size-140p capitalize"
                                            },
                                            [
                                              _vm.channel === null
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("CONNECTING")
                                                      )
                                                    )
                                                  ])
                                                : _vm.channel === false
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NEW"))
                                                    )
                                                  ])
                                                : (_vm.channel.orders !== 0 &&
                                                    (_vm.product.data.business
                                                      .hoo[_vm.today_day]
                                                      .is24 ||
                                                      (_vm.today_time_elapsed >=
                                                        0 &&
                                                        _vm.today_time_elapsed <=
                                                          100))) ||
                                                  (_vm.channel.online === 0 &&
                                                    (_vm.product.data.business
                                                      .hoo[_vm.today_day]
                                                      .is24 ||
                                                      (_vm.today_time_elapsed >=
                                                        0 &&
                                                        _vm.today_time_elapsed <=
                                                          100)))
                                                ? _c("span", [
                                                    _vm.channel.orders === 0
                                                      ? _c("span", [
                                                          _vm._v("Notice")
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getStatusChangeData(
                                                                _vm.channel
                                                                  .orders
                                                              ).label
                                                            )
                                                          )
                                                        ])
                                                  ])
                                                : _vm.product.data.business.hoo[
                                                    _vm.today_day
                                                  ].is24
                                                ? _c("span", [_vm._v("Open")])
                                                : _vm.product.data.business.hoo[
                                                    _vm.today_day
                                                  ].isClosed
                                                ? _c("span", [_vm._v("Closed")])
                                                : _vm.today_time_elapsed < 0 &&
                                                  _vm.today_time_elapsed > -10
                                                ? _c("span", [
                                                    _vm._v("Opening Soon")
                                                  ])
                                                : _vm.today_time_elapsed < 0 ||
                                                  _vm.today_time_elapsed > 100
                                                ? _c("span", [_vm._v("Closed")])
                                                : _vm.today_time_elapsed >=
                                                    90 &&
                                                  _vm.today_time_elapsed <= 100
                                                ? _c("span", [
                                                    _vm._v("Closing Soon")
                                                  ])
                                                : _c("span", [_vm._v("Open")])
                                            ]
                                          ),
                                          _vm.channel === false
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-weight-regular font-size-100p block on-top-sm",
                                                  attrs: { slot: "subtitle" },
                                                  slot: "subtitle"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "EMPTY_COMMUNICATION"
                                                        )
                                                      ) +
                                                      "\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.channel
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-weight-regular font-size-100p block on-top-sm",
                                                  attrs: { slot: "subtitle" },
                                                  slot: "subtitle"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _vm.today_day
                                                        ? [
                                                            _vm.product.data
                                                              .business.hoo[
                                                              _vm.today_day
                                                            ].is24
                                                              ? [
                                                                  _vm._v(
                                                                    "24 Hours"
                                                                  )
                                                                ]
                                                              : _vm.product.data
                                                                  .business.hoo[
                                                                  _vm.today_day
                                                                ].isClosed
                                                              ? [
                                                                  _vm._v(
                                                                    "Not open today."
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "t12format"
                                                                        )(
                                                                          _vm
                                                                            .product
                                                                            .data
                                                                            .business
                                                                            .hoo[
                                                                            _vm
                                                                              .today_day
                                                                          ].open
                                                                        )
                                                                      ) +
                                                                      "-" +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "t12format"
                                                                        )(
                                                                          _vm
                                                                            .product
                                                                            .data
                                                                            .business
                                                                            .hoo[
                                                                            _vm
                                                                              .today_day
                                                                          ]
                                                                            .close
                                                                        )
                                                                      ) +
                                                                      "\n                  "
                                                                  )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.channel && _vm.channel.online === 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg",
                                      attrs: { slot: "right" },
                                      slot: "right"
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            appear: "",
                                            "enter-active-class":
                                              "animated400 bounceIn",
                                            "leave-active-class":
                                              "animated400 bounceOut"
                                          }
                                        },
                                        [
                                          /*!isConciergeOnline &&*/ !_vm.clients
                                            .length ||
                                          _vm.channelFetchActiveClientsRequestsCount ||
                                          _vm.channelFetchActiveClientsRequestActive
                                            ? _c(
                                                "div",
                                                {
                                                  key: "chip-work",
                                                  staticClass:
                                                    "chip-live chip-live-blue"
                                                },
                                                [
                                                  _c("q-icon", {
                                                    attrs: {
                                                      color: "white",
                                                      name: "ion-code-working"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm.isConciergeOnline
                                            ? _c(
                                                "div",
                                                {
                                                  key: "chip-live",
                                                  staticClass: "chip-live"
                                                },
                                                [_vm._v("LIVE")]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.product.data.business.hoo[_vm.today_day].isClosed ||
                  _vm.today_time_elapsed < 0 ||
                  _vm.today_time_elapsed > 100
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "text-center on-top-xl text-weight-semibold text-shallow capitalize"
                        },
                        [
                          _vm._v(
                            "\n    " +
                              _vm._s(_vm.$t("COMMUNICATION.LAST")) +
                              "\n  "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.channel !== null && _vm.channel !== false
                    ? [
                        _vm.kioskMode === false
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "items-center margin-auto-lr row justify-around"
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      appear: "",
                                      "enter-active-class":
                                        "animated fadeInUp animated-d800",
                                      "leave-active-class":
                                        "animated fadeOutDown animated-d800"
                                    }
                                  },
                                  [
                                    _vm.channel && _vm.channel.loyalty_card
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "on-top-xl on-bottom full-width text-center text-tertiary text-system-brand text-weight-bold",
                                            staticStyle: { "font-size": "24px" }
                                          },
                                          [_vm._v("Loyalty Card")]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      appear: "",
                                      "enter-active-class":
                                        "animated fadeInUp animated-d800",
                                      "leave-active-class":
                                        "animated fadeOut animated-d800"
                                    }
                                  },
                                  [
                                    _vm.channel && _vm.channel.loyalty_card
                                      ? _c(
                                          "q-card",
                                          {
                                            directives: [
                                              {
                                                name: "touch-pan",
                                                rawName: "v-touch-pan.noMouse",
                                                value: function(obj) {
                                                  _vm.setCardIntent(
                                                    obj,
                                                    "reward",
                                                    _vm.sendLoyaltyRequest
                                                  )
                                                },
                                                expression:
                                                  "(obj) => { setCardIntent(obj, 'reward', sendLoyaltyRequest) }",
                                                modifiers: { noMouse: true }
                                              }
                                            ],
                                            ref: "product-card-reward",
                                            staticClass:
                                              "limit-width-420 overflow-hidden q-card-grouped q-card-widget q-card-widget-guard q-card-title-columns",
                                            attrs: {
                                              inline: "",
                                              disabled:
                                                !_vm.account.isLoggedIn ||
                                                ((_vm.accountInitProcessing ||
                                                  _vm.account.isLoggedIn !==
                                                    false) &&
                                                  (!_vm.account ||
                                                    _vm.account
                                                      .subscriptionProcess ||
                                                    _vm.account.subscribed ===
                                                      null))
                                            }
                                          },
                                          [
                                            _vm.accountInitProcessing
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-system-brand",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      width: "100%",
                                                      "font-size": "35px",
                                                      "text-align": "center",
                                                      "background-color":
                                                        "rgba(0,0,0,0.76)",
                                                      "z-index": "1",
                                                      "font-weight": "700",
                                                      color: "#fff",
                                                      "padding-top": "62px",
                                                      "text-shadow":
                                                        "0px 0px 10px black",
                                                      height:
                                                        "calc(100% - 52px)"
                                                    },
                                                    attrs: {
                                                      "style-disabled": {
                                                        height: _vm.$q.platform
                                                          .has.touch
                                                          ? "100%"
                                                          : "calc(100% - 52px)"
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("q-spinner-puff", {
                                                      staticClass:
                                                        "margin-auto-lr loading-spinner loading-spinner-gold",
                                                      staticStyle: {
                                                        color: "#F4A724",
                                                        display: "block"
                                                      },
                                                      attrs: { size: "140px" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("q-card-title", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "q-card-title-columns-wrapper"
                                                },
                                                [
                                                  _vm.product.data.business
                                                    .loyalty &&
                                                  _vm.product.data.business
                                                    .loyalty.image
                                                    ? _c("div", {
                                                        staticClass:
                                                          "q-card-title-columns-hero",
                                                        style:
                                                          "background-image: url(" +
                                                          _vm.product.data
                                                            .business.loyalty
                                                            .image +
                                                          "); background-position: center;"
                                                      })
                                                    : _c("div", {
                                                        staticClass:
                                                          "q-card-title-columns-hero",
                                                        staticStyle: {
                                                          "background-image":
                                                            "url(https://res.cloudinary.com/letsbutterfly/image/upload/v1690358205/wings-app/features/default.loyalty_image.jpg)",
                                                          "background-position":
                                                            "cover"
                                                        }
                                                      }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center full-width text-weight-semibold text-system-brand"
                                                    },
                                                    [
                                                      _c(
                                                        "q-chip",
                                                        {
                                                          staticClass:
                                                            "float-right",
                                                          attrs: {
                                                            "data-label": "",
                                                            "text-color":
                                                              "subinfo"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Card #" +
                                                              _vm._s(
                                                                _vm.account
                                                                  .user_payload
                                                                  .modules
                                                                  .loyalty_card
                                                                  .number
                                                              )
                                                          )
                                                        ]
                                                      ),
                                                      _vm.account.user_payload
                                                        .modules.loyalty_card
                                                        .stamps ===
                                                      _vm.product.data.business
                                                        .loyalty.stamps
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-left no-margin"
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticClass:
                                                                    "heartbeat",
                                                                  staticStyle: {
                                                                    margin:
                                                                      "10px",
                                                                    "margin-left":
                                                                      "0"
                                                                  },
                                                                  attrs: {
                                                                    name:
                                                                      "ion-gift",
                                                                    color:
                                                                      "value",
                                                                    size: "44px"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-value text-weight-bold line-height-sm text-left"
                                                              },
                                                              [
                                                                _c(
                                                                  "strong",
                                                                  {
                                                                    staticClass:
                                                                      "text-core"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Free Coffee"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("br"),
                                                                _vm._v(
                                                                  "Congratulations on your reward!\n              "
                                                                )
                                                              ]
                                                            ),
                                                            _vm.account
                                                              .user_payload
                                                              .modules
                                                              .loyalty_card
                                                              .carryover
                                                              ? [
                                                                  _c(
                                                                    "q-card-separator",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-bottom":
                                                                          "20px",
                                                                        "margin-top":
                                                                          "20px"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-value text-weight-bold line-height-sm text-left"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                  +" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .account
                                                                              .user_payload
                                                                              .modules
                                                                              .loyalty_card
                                                                              .carryover
                                                                          ) +
                                                                          " carried over\n                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-left"
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        _vm
                                                                          .account
                                                                          .user_payload
                                                                          .modules
                                                                          .loyalty_card
                                                                          .carryover,
                                                                        function(
                                                                          i
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                key:
                                                                                  "loyalty-co-" +
                                                                                  i,
                                                                                staticClass:
                                                                                  "q-chip q-chip-numerator q-chip-info inline-block bg-value-gradient"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "q-icon",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "margin-left":
                                                                                        "-2px",
                                                                                      "margin-top":
                                                                                        "-5px"
                                                                                    },
                                                                                    attrs: {
                                                                                      name:
                                                                                        "ion-ios-gift",
                                                                                      color:
                                                                                        "white"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _c(
                                                                    "q-card-separator",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-bottom":
                                                                          "20px",
                                                                        "margin-top":
                                                                          "20px"
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              : _c(
                                                                  "q-card-separator",
                                                                  {
                                                                    staticStyle: {
                                                                      "margin-bottom":
                                                                        "20px",
                                                                      "margin-top":
                                                                        "50px"
                                                                    }
                                                                  }
                                                                ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-subinfo line-height-xs font-size-80p text-left"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Tap to share your PIN and claim your reward."
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-left no-margin"
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "10px",
                                                                    "margin-left":
                                                                      "0"
                                                                  },
                                                                  attrs: {
                                                                    name:
                                                                      "ion-gift",
                                                                    color:
                                                                      "value",
                                                                    size: "44px"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-value text-weight-bold line-height-sm text-left"
                                                              },
                                                              [
                                                                _vm.account
                                                                  .isLoggedIn ===
                                                                false
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Coffee #" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .product
                                                                              .data
                                                                              .business
                                                                              .loyalty
                                                                              .stamps +
                                                                              1
                                                                          ) +
                                                                          " is on us"
                                                                      )
                                                                    ])
                                                                  : _c(
                                                                      "strong",
                                                                      {
                                                                        staticClass:
                                                                          "text-core"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .product
                                                                              .data
                                                                              .business
                                                                              .loyalty
                                                                              .stamps -
                                                                              _vm
                                                                                .account
                                                                                .user_payload
                                                                                .modules
                                                                                .loyalty_card
                                                                                .stamps
                                                                          ) +
                                                                            " more to go"
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-left"
                                                              },
                                                              [
                                                                _vm._l(
                                                                  _vm.product
                                                                    .data
                                                                    .business
                                                                    .loyalty
                                                                    .stamps,
                                                                  function(i) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          "loyalty-reward-" +
                                                                          i,
                                                                        staticClass:
                                                                          "inline-block",
                                                                        attrs: {
                                                                          id:
                                                                            "loyalty-reward-" +
                                                                            i
                                                                        }
                                                                      },
                                                                      [
                                                                        i >
                                                                        _vm
                                                                          .account
                                                                          .user_payload
                                                                          .modules
                                                                          .loyalty_card
                                                                          .stamps
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "q-chip q-chip-numerator q-chip-info inline-block animate-pop",
                                                                                style:
                                                                                  i >
                                                                                  9
                                                                                    ? "padding-left:7px !important"
                                                                                    : ""
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                  " +
                                                                                    _vm._s(
                                                                                      i
                                                                                    ) +
                                                                                    "\n                "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "q-chip q-chip-numerator q-chip-info inline-block",
                                                                                class:
                                                                                  i <=
                                                                                  _vm
                                                                                    .account
                                                                                    .user_payload
                                                                                    .modules
                                                                                    .loyalty_card
                                                                                    .stamps
                                                                                    ? "q-chip-numerator-filled"
                                                                                    : ""
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/checkmark.circle.fill.svg"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "q-chip q-chip-numerator q-chip-info inline-block bg-core-gradient"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticStyle: {
                                                                          "margin-left":
                                                                            "-2px",
                                                                          "margin-top":
                                                                            "-5px"
                                                                        },
                                                                        attrs: {
                                                                          name:
                                                                            "ion-ios-gift",
                                                                          color:
                                                                            "white"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              2
                                                            ),
                                                            _vm.account
                                                              .user_payload
                                                              .modules
                                                              .loyalty_card
                                                              .carryover
                                                              ? [
                                                                  _c(
                                                                    "q-card-separator",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-bottom":
                                                                          "20px",
                                                                        "margin-top":
                                                                          "20px"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-value text-weight-bold line-height-sm text-left"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                  " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .account
                                                                              .user_payload
                                                                              .modules
                                                                              .loyalty_card
                                                                              .carryover
                                                                          ) +
                                                                          " free carried over\n                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-left"
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        Array.from(
                                                                          {
                                                                            length:
                                                                              _vm
                                                                                .account
                                                                                .user_payload
                                                                                .modules
                                                                                .loyalty_card
                                                                                .carryover
                                                                          },
                                                                          function(
                                                                            _,
                                                                            i
                                                                          ) {
                                                                            return (
                                                                              i +
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                        function(
                                                                          value,
                                                                          index
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                key:
                                                                                  "loyalty-co-" +
                                                                                  index,
                                                                                staticClass:
                                                                                  "q-chip q-chip-numerator q-chip-info inline-block bg-value-gradient"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "q-icon",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "margin-left":
                                                                                        "-2px",
                                                                                      "margin-top":
                                                                                        "-5px"
                                                                                    },
                                                                                    attrs: {
                                                                                      name:
                                                                                        "ion-ios-gift",
                                                                                      color:
                                                                                        "white"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              : _vm._e(),
                                                            _c(
                                                              "q-card-separator",
                                                              {
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "20px",
                                                                  "margin-top":
                                                                    "20px"
                                                                }
                                                              }
                                                            ),
                                                            !_vm.account
                                                              .isLoggedIn
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-subinfo line-height-xs font-size-80p text-left"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Login, for free, to acquire this loyalty card."
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-subinfo line-height-xs font-size-80p text-left"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Tap to share your card with a PIN."
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("q-card-separator"),
                                            _c(
                                              "q-card-actions",
                                              [
                                                _c(
                                                  "q-btn",
                                                  {
                                                    staticClass: "full-width",
                                                    attrs: {
                                                      color: "primary",
                                                      flat: "",
                                                      rounded: "",
                                                      label: "Request Reward",
                                                      disabled:
                                                        !_vm.account
                                                          .isLoggedIn ||
                                                        ((_vm.accountInitProcessing ||
                                                          _vm.account
                                                            .isLoggedIn !==
                                                            false) &&
                                                          (!_vm.account ||
                                                            _vm.account
                                                              .subscriptionProcess ||
                                                            _vm.account
                                                              .subscribed ===
                                                              null))
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.sendLoyaltyRequest
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "q-tooltip",
                                                      {
                                                        attrs: {
                                                          delay: 2000,
                                                          inverted: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n            Request Reward\n          "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.product.data.uri ===
                        "caff_cafe-e664eb2afc1e9cccf0f13c05444b326d"
                          ? _c(
                              "div",
                              {
                                staticClass: "full-width layout-padding",
                                staticStyle: { "margin-bottom": "-40px" },
                                attrs: { hidden: "" }
                              },
                              [
                                _c(
                                  "masonry",
                                  {
                                    attrs: {
                                      cols: { default: 3, 900: 2, 660: 1 },
                                      gutter: { default: 20, 680: 10 }
                                    }
                                  },
                                  [
                                    _c(
                                      "q-list",
                                      {
                                        staticClass:
                                          "text-system-brand text-weight-medium q-list-depth",
                                        staticStyle: {
                                          "max-width": "320px !important",
                                          margin: "10px auto !important"
                                        }
                                      },
                                      [
                                        _c(
                                          "q-list-header",
                                          {
                                            staticClass:
                                              "no-margin-top text-weight-bold text-tertiary module-title-size"
                                          },
                                          [
                                            _vm._v(
                                              "\n          Most Loyal\n        "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "q-item",
                                          [
                                            _c("q-item-side", {
                                              staticClass: "text-center",
                                              attrs: {
                                                avatar:
                                                  "https://res.cloudinary.com/letsbutterfly/image/upload/v1723729975/wings-app/features/nkduepjz5npfksifm8kl.jpg"
                                              }
                                            }),
                                            _c(
                                              "q-item-main",
                                              [
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { label: "" } },
                                                  [_vm._v("Yehia Akoush")]
                                                ),
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { sublabel: "" } },
                                                  [
                                                    _c("q-icon", {
                                                      attrs: {
                                                        color: "educate",
                                                        name: "ion-leaf"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n              Saved 47 cards"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-side",
                                              { staticClass: "text-center" },
                                              [
                                                _c("q-icon", {
                                                  staticClass: "on-left-sm",
                                                  attrs: {
                                                    color: "gold",
                                                    name: "ion-ribbon",
                                                    size: "32px"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-list",
                                      {
                                        staticClass:
                                          "text-system-brand text-weight-medium q-list-depth",
                                        staticStyle: {
                                          "max-width": "320px !important",
                                          margin: "10px auto !important"
                                        }
                                      },
                                      [
                                        _c(
                                          "q-list-header",
                                          {
                                            staticClass:
                                              "no-margin-top text-weight-bold text-tertiary module-title-size"
                                          },
                                          [
                                            _vm._v(
                                              "\n          Sustainability Report\n        "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "q-item",
                                          [
                                            _c(
                                              "q-item-side",
                                              { staticClass: "text-center" },
                                              [
                                                _c("q-icon", {
                                                  staticClass: "on-left-sm",
                                                  attrs: {
                                                    color: "educate",
                                                    name: "ion-leaf",
                                                    size: "32px"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-main",
                                              [
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { label: "" } },
                                                  [_vm._v("10,162 Stamps")]
                                                ),
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { label: "" } },
                                                  [_vm._v("941 Cards")]
                                                ),
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { label: "" } },
                                                  [_vm._v("42,108 Updates")]
                                                ),
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { sublabel: "" } },
                                                  [_vm._v("Since July 2023")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.channel && _vm.product.data.business.services
                          ? _c(
                              "div",
                              {
                                staticClass: "full-width layout-padding",
                                class: {
                                  "disabled-ignore":
                                    _vm.product.data.business.hoo[_vm.today_day]
                                      .isClosed ||
                                    _vm.today_time_elapsed < 0 ||
                                    _vm.today_time_elapsed > 100
                                }
                              },
                              [
                                _c(
                                  "masonry",
                                  {
                                    attrs: {
                                      cols: { default: 3, 900: 2, 660: 1 },
                                      gutter: { default: 20, 680: 10 }
                                    }
                                  },
                                  [
                                    _vm.settings_dev_presense &&
                                    _vm.kioskMode === false
                                      ? _c(
                                          "q-list",
                                          {
                                            staticClass:
                                              "text-system-brand text-weight-medium q-list-depth"
                                          },
                                          [
                                            _c(
                                              "q-list-header",
                                              {
                                                staticClass:
                                                  "no-margin-top text-weight-bold text-tertiary module-title-size"
                                              },
                                              [
                                                _c("q-icon", {
                                                  staticClass: "on-left-sm",
                                                  attrs: {
                                                    color: _vm.amIOnline
                                                      ? "educate"
                                                      : "protect",
                                                    name:
                                                      "ion-information-circle",
                                                    size: "32px"
                                                  }
                                                }),
                                                _vm._v(
                                                  " Active Clients\n          "
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item",
                                              {
                                                attrs: {
                                                  link: "",
                                                  tag: "label"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.channelFetchActiveClients()
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-item-side",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _c("q-icon", {
                                                      attrs: {
                                                        name: "ion-repeat",
                                                        color: _vm.anyDarkmode
                                                          ? ""
                                                          : "blue-grey-10",
                                                        size: "33px"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-main",
                                                  [
                                                    _c(
                                                      "q-item-tile",
                                                      { attrs: { label: "" } },
                                                      [_vm._v("Fetch Clients")]
                                                    ),
                                                    _c(
                                                      "q-item-tile",
                                                      {
                                                        attrs: { sublabel: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Refresh list of users"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.clients.length
                                              ? _c(
                                                  "div",
                                                  _vm._l(_vm.clients, function(
                                                    client
                                                  ) {
                                                    return _c(
                                                      "q-item",
                                                      {
                                                        key: client.uuid,
                                                        attrs: { item: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "q-item-side",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            client.state &&
                                                            client.state
                                                              .role === "admin"
                                                              ? _c("q-icon", {
                                                                  attrs: {
                                                                    name:
                                                                      "ion-medal",
                                                                    color:
                                                                      "gray",
                                                                    size: "33px"
                                                                  }
                                                                })
                                                              : _c("q-icon", {
                                                                  attrs: {
                                                                    name:
                                                                      "ion-contact",
                                                                    color:
                                                                      client.uuid ===
                                                                      _vm.uuid
                                                                        ? "educate"
                                                                        : "gray",
                                                                    size: "33px"
                                                                  }
                                                                })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "q-item-main",
                                                          [
                                                            _c(
                                                              "q-item-tile",
                                                              {
                                                                attrs: {
                                                                  label: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                  " +
                                                                    _vm._s(
                                                                      client.state &&
                                                                        client
                                                                          .state
                                                                          .role ===
                                                                          "admin"
                                                                        ? "Admin"
                                                                        : "User"
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                                client.uuid ===
                                                                _vm.uuid
                                                                  ? _c(
                                                                      "q-chip",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "YOU"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "q-item-tile",
                                                              {
                                                                attrs: {
                                                                  sublabel: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "q-chip",
                                                                  {
                                                                    attrs: {
                                                                      dense: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        client.uuid
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  1
                                                )
                                              : _c("div", [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "text-center"
                                                    },
                                                    [_vm._v("No clients")]
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.qrCodeMode === true
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "max-width": "320px !important",
                                              margin: "10px auto !important"
                                            }
                                          },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "text-center font-size-140p text-primary text-weight-bold",
                                                staticStyle: {
                                                  "margin-bottom": "-12px"
                                                }
                                              },
                                              [_vm._v("SCAN & JOIN")]
                                            ),
                                            _vm.channel &&
                                            _vm.channel.loyalty_card &&
                                            false
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "block text-grey text-weight-regular text-center"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Get rewards while staying current."
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "block text-grey text-weight-regular text-center"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Subscribe to stay current."
                                                    )
                                                  ]
                                                ),
                                            _c(
                                              "q-list",
                                              {
                                                staticClass:
                                                  "text-system-brand gradient-bottom text-weight-medium q-list-depth",
                                                staticStyle: {
                                                  "max-width":
                                                    "320px !important",
                                                  margin: "10px auto !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-item",
                                                  [
                                                    _c(
                                                      "q-item-main",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [
                                                        _vm.product.data
                                                          .qrcode_ref
                                                          ? _c("img", {
                                                              staticStyle: {
                                                                width: "100%",
                                                                "border-radius":
                                                                  "2em"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.product
                                                                    .data
                                                                    .qrcode_ref
                                                              }
                                                            })
                                                          : _c("span", [
                                                              _vm._v(
                                                                "A QR Code was not generated for this channel."
                                                              )
                                                            ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.product.data.business.groups &&
                                    _vm.product.data.business.groups.order &&
                                    _vm.product.data.business.groups.order
                                      .length
                                      ? [
                                          _vm._l(
                                            _vm.productServicesGroupedByGroups,
                                            function(group) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "max-width":
                                                        "320px !important",
                                                      margin:
                                                        "10px auto !important"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "text-blue-grey-7 text-weight-bold text-center font-size-140p",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "-12px",
                                                          "text-transform":
                                                            "uppercase"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.product.data
                                                              .business.groups
                                                              .list[group.id]
                                                              .title
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "block text-grey text-weight-regular text-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.product.data
                                                              .business.groups
                                                              .list[group.id]
                                                              .description
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "q-list",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "key",
                                                            rawName: "v-key",
                                                            value:
                                                              "group-" +
                                                              group.id,
                                                            expression:
                                                              "`group-${group.id}`"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-system-brand text-weight-medium q-list-depth",
                                                        staticStyle: {
                                                          "max-width":
                                                            "320px !important",
                                                          margin:
                                                            "10px auto !important"
                                                        },
                                                        attrs: {
                                                          "gradient-bottom": "",
                                                          "link-no": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "q-list-header",
                                                          {
                                                            staticClass:
                                                              "text-weight-bold text-emphasis text-left font-size-140p",
                                                            staticStyle: {
                                                              "text-transform":
                                                                "capitalize"
                                                            },
                                                            attrs: {
                                                              hidden: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                  " +
                                                                _vm._s(
                                                                  _vm.product
                                                                    .data
                                                                    .business
                                                                    .groups
                                                                    .list[
                                                                    group.id
                                                                  ].title
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "block text-grey text-weight-regular",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "5px",
                                                                  "padding-bottom":
                                                                    "20px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.product
                                                                      .data
                                                                      .business
                                                                      .groups
                                                                      .list[
                                                                      group.id
                                                                    ]
                                                                      .description
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          group.services,
                                                          function(service) {
                                                            return [
                                                              _c(
                                                                "q-item",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "key",
                                                                      rawName:
                                                                        "v-key",
                                                                      value:
                                                                        "group-" +
                                                                        group.id +
                                                                        "-service-" +
                                                                        service.uuid,
                                                                      expression:
                                                                        "`group-${group.id}-service-${service.uuid}`"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    "data-click":
                                                                      "request(service.uuid)"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "q-item-side",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          class: {
                                                                            "opacity-4":
                                                                              _vm.serviceStatus(
                                                                                service.uuid
                                                                              ) !==
                                                                              0
                                                                          },
                                                                          attrs: {
                                                                            src:
                                                                              "/statics/services/" +
                                                                              _vm
                                                                                .product
                                                                                .data
                                                                                .business
                                                                                .components[
                                                                                service
                                                                                  .components[0]
                                                                              ]
                                                                                .categoryId +
                                                                              "." +
                                                                              _vm
                                                                                .product
                                                                                .data
                                                                                .business
                                                                                .components[
                                                                                service
                                                                                  .components[0]
                                                                              ]
                                                                                .componentId +
                                                                              ".svg",
                                                                            width:
                                                                              "33"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) >= 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "absolute",
                                                                              staticStyle: {
                                                                                left:
                                                                                  "16px",
                                                                                height:
                                                                                  "36px"
                                                                              },
                                                                              attrs: {
                                                                                src:
                                                                                  "statics/_demo/cross-overlay.svg"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "absolute",
                                                                          staticStyle: {
                                                                            top:
                                                                              "0px",
                                                                            left:
                                                                              "4px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.serviceStatus(
                                                                            service.uuid
                                                                          ) ===
                                                                          0
                                                                            ? _c(
                                                                                "q-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "ion-checkmark-circle",
                                                                                    color:
                                                                                      "educate",
                                                                                    size:
                                                                                      "22px"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm.serviceStatus(
                                                                                service.uuid
                                                                              ) ===
                                                                              1
                                                                            ? _c(
                                                                                "q-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "ion-alert",
                                                                                    color:
                                                                                      "attention",
                                                                                    size:
                                                                                      "22px"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm.serviceStatus(
                                                                                service.uuid
                                                                              ) ===
                                                                              2
                                                                            ? _c(
                                                                                "q-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "ion-close-circle",
                                                                                    color:
                                                                                      "protect",
                                                                                    size:
                                                                                      "22px"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm.serviceStatus(
                                                                                service.uuid
                                                                              ) ===
                                                                              3
                                                                            ? _c(
                                                                                "q-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "statusFader",
                                                                                  attrs: {
                                                                                    name:
                                                                                      "ion-code-working",
                                                                                    color:
                                                                                      "purple-l2",
                                                                                    size:
                                                                                      "22px"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "q-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    name:
                                                                                      "ion-code",
                                                                                    color:
                                                                                      "shallow",
                                                                                    size:
                                                                                      "32px"
                                                                                  }
                                                                                }
                                                                              )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "q-item-main",
                                                                    {
                                                                      class: {
                                                                        "opacity-4":
                                                                          _vm.serviceStatus(
                                                                            service.uuid
                                                                          ) !==
                                                                          0
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "q-item-tile",
                                                                        {
                                                                          staticClass:
                                                                            "capitalize text-weight-semibold",
                                                                          attrs: {
                                                                            label:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          service.isNew
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "NEW"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            "\n                      " +
                                                                              _vm._s(
                                                                                service.label
                                                                                  ? service.label
                                                                                  : _vm
                                                                                      .Wings
                                                                                      .services
                                                                                      .list[
                                                                                      _vm
                                                                                        .product
                                                                                        .data
                                                                                        .business
                                                                                        .components[
                                                                                        service
                                                                                          .components[0]
                                                                                      ]
                                                                                        .categoryId
                                                                                    ][
                                                                                      _vm
                                                                                        .product
                                                                                        .data
                                                                                        .business
                                                                                        .components[
                                                                                        service
                                                                                          .components[0]
                                                                                      ]
                                                                                        .componentId
                                                                                    ]
                                                                                      .name
                                                                              ) +
                                                                              "\n                    "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      service
                                                                        .components
                                                                        .length ===
                                                                        1 &&
                                                                      _vm.isCustomDescriptor(
                                                                        _vm
                                                                          .product
                                                                          .data
                                                                          .business
                                                                          .components[
                                                                          service
                                                                            .components[0]
                                                                        ]
                                                                      )
                                                                        ? _c(
                                                                            "q-item-tile",
                                                                            {
                                                                              attrs: {
                                                                                sublabel:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "strong",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.getComponentDescriptors(
                                                                                        _vm
                                                                                          .product
                                                                                          .data
                                                                                          .business
                                                                                          .components[
                                                                                          service
                                                                                            .components[0]
                                                                                        ]
                                                                                      )
                                                                                        .availability[
                                                                                        _vm.serviceStatus(
                                                                                          service.uuid
                                                                                        )
                                                                                      ]
                                                                                        .label
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                      " +
                                                                                  _vm._s(
                                                                                    _vm.getComponentDescriptors(
                                                                                      _vm
                                                                                        .product
                                                                                        .data
                                                                                        .business
                                                                                        .components[
                                                                                        service
                                                                                          .components[0]
                                                                                      ]
                                                                                    )
                                                                                      .availability[
                                                                                      _vm.serviceStatus(
                                                                                        service.uuid
                                                                                      )
                                                                                    ]
                                                                                      .sublabel
                                                                                  ) +
                                                                                  "\n                    "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      service.description &&
                                                                      service
                                                                        .description
                                                                        .length
                                                                        ? _c(
                                                                            "q-item-tile",
                                                                            {
                                                                              attrs: {
                                                                                sublabel:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                      " +
                                                                                  _vm._s(
                                                                                    service.description
                                                                                  ) +
                                                                                  "\n                    "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  ),
                                                                  service.cost ||
                                                                  service.cost ===
                                                                    0
                                                                    ? _c(
                                                                        "q-item-side",
                                                                        {
                                                                          class: {
                                                                            "opacity-3":
                                                                              service.cost ===
                                                                              0
                                                                          },
                                                                          attrs: {
                                                                            right:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          service.partial
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "+"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            "\n                    " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "nformat"
                                                                                )(
                                                                                  service.cost,
                                                                                  "0,0.00"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "small",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .currency
                                                                                    .label
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          )
                                        ]
                                      : _vm._l(
                                          _vm.productServicesGroupedByCategories,
                                          function(services, serviceCategory) {
                                            return _vm._l(
                                              _vm.componentsGroupedByComponents(
                                                services
                                              ),
                                              function(
                                                servicesByComponent,
                                                sindex
                                              ) {
                                                return _c(
                                                  "q-list",
                                                  {
                                                    key:
                                                      serviceCategory +
                                                      "-" +
                                                      sindex +
                                                      "-kiosk",
                                                    staticClass:
                                                      "text-system-brand text-weight-medium q-list-depth",
                                                    staticStyle: {
                                                      "max-width":
                                                        "320px !important",
                                                      margin:
                                                        "10px auto !important"
                                                    },
                                                    attrs: {
                                                      "gradient-bottom": ""
                                                    }
                                                  },
                                                  [
                                                    Object.keys(
                                                      servicesByComponent
                                                    ).length > 1
                                                      ? _c(
                                                          "q-list-header",
                                                          {
                                                            staticClass:
                                                              "text-weight-bold text-grey text-left"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n              " +
                                                                _vm._s(
                                                                  _vm.Wings
                                                                    .services
                                                                    .list[
                                                                    _vm.product
                                                                      .data
                                                                      .business
                                                                      .components[
                                                                      _vm
                                                                        .product
                                                                        .data
                                                                        .business
                                                                        .services[
                                                                        Object.keys(
                                                                          servicesByComponent
                                                                        )[0]
                                                                      ]
                                                                        .components[0]
                                                                    ].categoryId
                                                                  ][
                                                                    _vm.product
                                                                      .data
                                                                      .business
                                                                      .components[
                                                                      _vm
                                                                        .product
                                                                        .data
                                                                        .business
                                                                        .services[
                                                                        Object.keys(
                                                                          servicesByComponent
                                                                        )[0]
                                                                      ]
                                                                        .components[0]
                                                                    ]
                                                                      .componentId
                                                                  ].name
                                                                ) +
                                                                "\n            "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._l(
                                                      servicesByComponent,
                                                      function(service) {
                                                        return [
                                                          _c(
                                                            "q-item",
                                                            {
                                                              key:
                                                                "service-" +
                                                                service.uuid +
                                                                "-kiosk",
                                                              style:
                                                                Object.keys(
                                                                  servicesByComponent
                                                                ).length === 1
                                                                  ? "margin-top: -4px"
                                                                  : "",
                                                              attrs: {
                                                                item: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-item-side",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    class: {
                                                                      "opacity-4":
                                                                        _vm.serviceStatus(
                                                                          service.uuid
                                                                        ) !== 0
                                                                    },
                                                                    attrs: {
                                                                      src:
                                                                        "/statics/services/" +
                                                                        _vm
                                                                          .product
                                                                          .data
                                                                          .business
                                                                          .components[
                                                                          service
                                                                            .components[0]
                                                                        ]
                                                                          .categoryId +
                                                                        "." +
                                                                        _vm
                                                                          .product
                                                                          .data
                                                                          .business
                                                                          .components[
                                                                          service
                                                                            .components[0]
                                                                        ]
                                                                          .componentId +
                                                                        ".svg",
                                                                      width:
                                                                        "33"
                                                                    }
                                                                  }),
                                                                  _vm.serviceStatus(
                                                                    service.uuid
                                                                  ) >= 2
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "absolute",
                                                                          staticStyle: {
                                                                            left:
                                                                              "16px",
                                                                            height:
                                                                              "36px"
                                                                          },
                                                                          attrs: {
                                                                            src:
                                                                              "statics/_demo/cross-overlay.svg"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "absolute",
                                                                      staticStyle: {
                                                                        top:
                                                                          "0px",
                                                                        left:
                                                                          "4px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) === 0
                                                                        ? _c(
                                                                            "q-icon",
                                                                            {
                                                                              attrs: {
                                                                                name:
                                                                                  "ion-checkmark-circle",
                                                                                color:
                                                                                  "educate",
                                                                                size:
                                                                                  "22px"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm.serviceStatus(
                                                                            service.uuid
                                                                          ) ===
                                                                          1
                                                                        ? _c(
                                                                            "q-icon",
                                                                            {
                                                                              attrs: {
                                                                                name:
                                                                                  "ion-alert",
                                                                                color:
                                                                                  "attention",
                                                                                size:
                                                                                  "22px"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm.serviceStatus(
                                                                            service.uuid
                                                                          ) ===
                                                                          2
                                                                        ? _c(
                                                                            "q-icon",
                                                                            {
                                                                              attrs: {
                                                                                name:
                                                                                  "ion-close-circle",
                                                                                color:
                                                                                  "protect",
                                                                                size:
                                                                                  "22px"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm.serviceStatus(
                                                                            service.uuid
                                                                          ) ===
                                                                          3
                                                                        ? _c(
                                                                            "q-icon",
                                                                            {
                                                                              staticClass:
                                                                                "statusFader",
                                                                              attrs: {
                                                                                name:
                                                                                  "ion-code-working",
                                                                                color:
                                                                                  "purple-l2",
                                                                                size:
                                                                                  "22px"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "q-icon",
                                                                            {
                                                                              attrs: {
                                                                                name:
                                                                                  "ion-code",
                                                                                color:
                                                                                  "shallow",
                                                                                size:
                                                                                  "32px"
                                                                              }
                                                                            }
                                                                          )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "q-item-main",
                                                                {
                                                                  class: {
                                                                    "opacity-4":
                                                                      _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) !== 0
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "q-item-tile",
                                                                    {
                                                                      staticClass:
                                                                        "capitalize text-weight-semibold",
                                                                      attrs: {
                                                                        label:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                    " +
                                                                          _vm._s(
                                                                            service.label
                                                                              ? service.label
                                                                              : _vm
                                                                                  .Wings
                                                                                  .services
                                                                                  .list[
                                                                                  _vm
                                                                                    .product
                                                                                    .data
                                                                                    .business
                                                                                    .components[
                                                                                    service
                                                                                      .components[0]
                                                                                  ]
                                                                                    .categoryId
                                                                                ][
                                                                                  _vm
                                                                                    .product
                                                                                    .data
                                                                                    .business
                                                                                    .components[
                                                                                    service
                                                                                      .components[0]
                                                                                  ]
                                                                                    .componentId
                                                                                ]
                                                                                  .name
                                                                          ) +
                                                                          "\n                  "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  service
                                                                    .components
                                                                    .length ===
                                                                    1 &&
                                                                  _vm.isCustomDescriptor(
                                                                    _vm.product
                                                                      .data
                                                                      .business
                                                                      .components[
                                                                      service
                                                                        .components[0]
                                                                    ]
                                                                  )
                                                                    ? _c(
                                                                        "q-item-tile",
                                                                        {
                                                                          attrs: {
                                                                            sublabel:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getComponentDescriptors(
                                                                                    _vm
                                                                                      .product
                                                                                      .data
                                                                                      .business
                                                                                      .components[
                                                                                      service
                                                                                        .components[0]
                                                                                    ]
                                                                                  )
                                                                                    .availability[
                                                                                    _vm.serviceStatus(
                                                                                      service.uuid
                                                                                    )
                                                                                  ]
                                                                                    .label
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                    " +
                                                                              _vm._s(
                                                                                _vm.getComponentDescriptors(
                                                                                  _vm
                                                                                    .product
                                                                                    .data
                                                                                    .business
                                                                                    .components[
                                                                                    service
                                                                                      .components[0]
                                                                                  ]
                                                                                )
                                                                                  .availability[
                                                                                  _vm.serviceStatus(
                                                                                    service.uuid
                                                                                  )
                                                                                ]
                                                                                  .sublabel
                                                                              ) +
                                                                              "\n                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  service.description &&
                                                                  service
                                                                    .description
                                                                    .length
                                                                    ? _c(
                                                                        "q-item-tile",
                                                                        {
                                                                          attrs: {
                                                                            sublabel:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                    " +
                                                                              _vm._s(
                                                                                service.description
                                                                              ) +
                                                                              "\n                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              service.cost ||
                                                              service.cost === 0
                                                                ? _c(
                                                                    "q-item-side",
                                                                    {
                                                                      class: {
                                                                        "opacity-3":
                                                                          service.cost ===
                                                                          0
                                                                      },
                                                                      attrs: {
                                                                        right:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      service.partial
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "+"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        "\n                  " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "nformat"
                                                                            )(
                                                                              service.cost,
                                                                              "0,0.00"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .currency
                                                                                .label
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              }
                                            )
                                          }
                                        )
                                  ],
                                  2
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-center text-grey-5 text-weight-regular",
                                    staticStyle: {
                                      padding: "40px 0px 0px 20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block font-size-60p on-top-xs"
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass:
                                              "inline-block font-size-100p spaced-width",
                                            class: {
                                              "text-gray":
                                                this.kioskMode === false,
                                              "text-educate":
                                                this.kioskMode === true
                                            },
                                            staticStyle: {
                                              "margin-left": "-5px"
                                            },
                                            attrs: { dense: "" },
                                            on: { click: _vm.kioskModeToggle }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-system-brand"
                                              },
                                              [
                                                _c("q-icon", {
                                                  attrs: {
                                                    name:
                                                      this.kioskMode === true
                                                        ? "ion-eye"
                                                        : "ion-eye-off",
                                                    size: "10"
                                                  }
                                                }),
                                                _vm._v(
                                                  "\n              Kiosk\n            "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass:
                                              "inline-block font-size-100p spaced-width",
                                            class: {
                                              "text-gray":
                                                this.qrCodeMode === false,
                                              "text-educate":
                                                this.qrCodeMode === true
                                            },
                                            attrs: { dense: "" },
                                            on: { click: _vm.qrModeToggle }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-system-brand"
                                              },
                                              [
                                                _c("q-icon", {
                                                  attrs: {
                                                    name:
                                                      this.qrCodeMode === true
                                                        ? "ion-eye"
                                                        : "ion-eye-off",
                                                    size: "10"
                                                  }
                                                }),
                                                _vm._v(
                                                  "\n              QR Code\n            "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogServiceDetails",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogServiceDetailsShow,
                        callback: function($$v) {
                          _vm.dialogServiceDetailsShow = $$v
                        },
                        expression: "dialogServiceDetailsShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [_vm._v("Service Details")])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "empower-light",
                                            "text-color": "empower",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.notifyMe()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Notify Me\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogServiceDetailsShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "block text-center text-weight-semibold text-system-brand"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/services/baking.cookies.svg",
                                      width: "33"
                                    }
                                  }),
                                  _c("br"),
                                  _vm._v(
                                    "\n          Dark Chocoloate\n          "
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm._v(
                                    "\n          Current Status: Not Available\n          "
                                  ),
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-close-circle",
                                      color: "protect",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "text-system-brand text-left" },
                                [
                                  _vm._v(
                                    '\n          Tap "Notify Me" to be notified (via SMS) when this service becomes available.\n          The notification will be sent to '
                                  ),
                                  _c("strong", [_vm._v("+1 508-847-8747")]),
                                  _vm._v(".\n        ")
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-system-brand text-left" },
                                [
                                  _vm._v(
                                    "\n          You will only be notified once the service is back and never again until you request a notifcation again.\n        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogPersonalize",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogPersonalizeShow,
                        callback: function($$v) {
                          _vm.dialogPersonalizeShow = $$v
                        },
                        expression: "dialogPersonalizeShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("PERSONALIZE.LABEL_TT")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase font-size-120p",
                                          attrs: {
                                            color: "tertiary-light",
                                            "text-color": "tertiary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.personalizeList()
                                              _vm.soundPlay("sheet_up")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              src:
                                                "/statics/_demo/add.fill_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "tertiary") +
                                                ".svg",
                                              height: "24"
                                            }
                                          }),
                                          _vm._v(
                                            "\n              Add\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogPersonalizeShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/chevron.compact.down_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "primary") +
                                                ".svg",
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "q-title text-subinfo slideInDown on-top-lg",
                                  staticStyle: {
                                    "padding-bottom": "20px",
                                    "line-height": "1.4em"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          Personalize your experience by adding your lifestyle preferences.\n        "
                                  )
                                ]
                              ),
                              !_vm.guardian_personas.count
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-center text-primary text-weight-bold font-size-120p animated800 flipInY animatedtimingCubic cursor-pointer",
                                        on: { click: _vm.personalizeList }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              "/statics/_demo/state.empty_heart" +
                                              (_vm.anyDarkmode ? "@dark" : "") +
                                              ".svg"
                                          }
                                        }),
                                        _c("br"),
                                        _vm._v(
                                          "Add your first personalization\n          "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e(),
                              _vm._l(_vm.guardian_personas.data, function(
                                personas,
                                group_id
                              ) {
                                return [
                                  personas.count
                                    ? _c(
                                        "q-list",
                                        {
                                          key: group_id,
                                          staticClass:
                                            "card text-family-brand full-width on-top-lg",
                                          attrs: { "no-border": "", link: "" }
                                        },
                                        [
                                          personas.count
                                            ? [
                                                _c(
                                                  "q-list-header",
                                                  {
                                                    staticClass:
                                                      "font-size-120p text-secondary text-center",
                                                    attrs: {
                                                      "compact-left": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(group_id) +
                                                        "\n              "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(personas.data, function(
                                                  payload,
                                                  persona
                                                ) {
                                                  return [
                                                    _c(
                                                      "transition",
                                                      {
                                                        key: [
                                                          "transition",
                                                          group_id,
                                                          persona,
                                                          payload
                                                        ].join("-"),
                                                        attrs: {
                                                          mode: "out-in",
                                                          appear: "",
                                                          "enter-active-class":
                                                            "animated800 fadeIn",
                                                          "leave-active-class":
                                                            "fadeOut animated400"
                                                        }
                                                      },
                                                      [
                                                        payload.status
                                                          ? _c(
                                                              "q-item",
                                                              {
                                                                key: [
                                                                  group_id,
                                                                  persona,
                                                                  payload
                                                                ].join("-"),
                                                                attrs: {
                                                                  link: ""
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.personaEdit(
                                                                      persona,
                                                                      payload
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "q-item-side",
                                                                  [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "brighten-50 dark-img-invert-100 on-left-sm",
                                                                      staticStyle: {
                                                                        width:
                                                                          "40px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          "/statics/_demo/persona." +
                                                                          _vm
                                                                            .offerings
                                                                            .personas
                                                                            .bases[
                                                                            persona
                                                                          ]
                                                                            .group +
                                                                          "." +
                                                                          persona +
                                                                          ".svg"
                                                                      }
                                                                    })
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "q-item-main",
                                                                  {
                                                                    staticClass:
                                                                      "font-size-160p text-weight-semibold"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-item-tile",
                                                                      {
                                                                        staticClass:
                                                                          "font-size-100p capitalize",
                                                                        attrs: {
                                                                          label:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            persona.replace(
                                                                              /\_/g,
                                                                              " "
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "q-item-side",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                    staticStyle: {
                                                                      width:
                                                                        "80px",
                                                                      height:
                                                                        "60px"
                                                                    },
                                                                    attrs: {
                                                                      right: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "transition",
                                                                      {
                                                                        attrs: {
                                                                          mode:
                                                                            "out-in",
                                                                          appear:
                                                                            "",
                                                                          "enter-active-class":
                                                                            "animated400 " +
                                                                            (payload.status ===
                                                                            null
                                                                              ? "fadeIn"
                                                                              : "flipInY"),
                                                                          "leave-active-class":
                                                                            "animated200 " +
                                                                            (payload.status ===
                                                                            null
                                                                              ? "fadeOut"
                                                                              : "flipOutY")
                                                                        }
                                                                      },
                                                                      [
                                                                        payload.status ===
                                                                        "health"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "health"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/avoid_protect.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-protect font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : payload.status ===
                                                                            "avoid"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "avoid"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/nosign_attention.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-attention font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : payload.status ===
                                                                            "good"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "good"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/heart_educate.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-educate font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "WANT"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "null"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "40px",
                                                                                      opacity:
                                                                                        "0.5"
                                                                                    },
                                                                                    attrs: {
                                                                                      width:
                                                                                        "40",
                                                                                      src:
                                                                                        "/statics/_demo/ellipsis.circle.fill_tertiary.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-faded no-margin text-weight-bold text-tertiary font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "NOT SET"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                })
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogLoyalty",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogLoyaltyShow,
                        callback: function($$v) {
                          _vm.dialogLoyaltyShow = $$v
                        },
                        expression: "dialogLoyaltyShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("LOYALTY.LABEL_TT")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.endLoyaltyRequest()
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/chevron.compact.down_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "primary") +
                                                ".svg",
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "q-title layout-padding no-padding text-subinfo slideInDown on-top-lg",
                                  staticStyle: {
                                    "padding-bottom": "20px",
                                    "line-height": "1.4em"
                                  }
                                },
                                [
                                  true || _vm.isConciergeOnline
                                    ? [_vm._v("Present PIN for stamping...")]
                                    : [
                                        _vm._v(
                                          "Waiting for staff to be online..."
                                        )
                                      ]
                                ],
                                2
                              ),
                              true || _vm.isConciergeOnline
                                ? [
                                    _c(
                                      "q-list",
                                      {
                                        staticClass:
                                          "card text-family-brand full-width on-top-lg",
                                        attrs: { "no-border": "" }
                                      },
                                      [
                                        _c(
                                          "q-list-header",
                                          {
                                            staticClass: "no-padding",
                                            staticStyle: {
                                              "line-height": "initial"
                                            },
                                            attrs: { compact: "" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-black full-width text-center text-system-brand text-weight-semibold uppercase",
                                                staticStyle: {
                                                  "font-size": "100px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.dialogLoyaltyShowPin
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "card text-family-brand" },
                                      [
                                        _c(
                                          "q-knob",
                                          {
                                            attrs: {
                                              medium: "",
                                              color: "empower",
                                              min: 0,
                                              max: 100,
                                              size: "100px",
                                              "line-width": "6",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.dialogLoyaltyShowTimerPercentage,
                                              callback: function($$v) {
                                                _vm.dialogLoyaltyShowTimerPercentage = $$v
                                              },
                                              expression:
                                                "dialogLoyaltyShowTimerPercentage"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "full-width no-margin text-empower text-family-brand text-weight-bold",
                                                staticStyle: {
                                                  "font-size": "33px",
                                                  "line-height": ".8em"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.dialogLoyaltyShowTimer
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-weight-bold uppercase",
                                                staticStyle: {
                                                  "font-size": "11px",
                                                  "margin-top": "4px",
                                                  "margin-bottom": "-14px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "SECONDS.COUNT",
                                                      _vm.dialogLoyaltyShowTimer,
                                                      {
                                                        count:
                                                          _vm.dialogLoyaltyShowTimer
                                                      }
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogPersonalizeList",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogPersonalizeListShow,
                        callback: function($$v) {
                          _vm.dialogPersonalizeListShow = $$v
                        },
                        expression: "dialogPersonalizeListShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(
                                  _vm._s(_vm.$t("PERSONALIZE_LIST.LABEL_TT"))
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _vm.settings_voice
                                ? _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "tertiary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.personalizeVoice()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "on-left-sm",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/mic_" +
                                                    (_vm.anyDarkmode
                                                      ? "white"
                                                      : "tertiary") +
                                                    ".svg",
                                                  height: "24"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.personalizeList()
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/chevron.compact.down_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "primary") +
                                                ".svg",
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _vm._l(_vm.guardian_personas.data, function(
                                personas,
                                group_id
                              ) {
                                return _c(
                                  "q-list",
                                  {
                                    key: group_id,
                                    staticClass:
                                      "card text-family-brand full-width on-top-lg",
                                    attrs: { "no-border": "", link: "" }
                                  },
                                  [
                                    _c(
                                      "q-list-header",
                                      {
                                        staticClass:
                                          "font-size-120p text-secondary text-center",
                                        attrs: { "compact-left": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(group_id) +
                                            "\n          "
                                        )
                                      ]
                                    ),
                                    _vm._l(personas.data, function(
                                      payload,
                                      persona
                                    ) {
                                      return _c(
                                        "q-item",
                                        {
                                          key: [
                                            group_id,
                                            persona,
                                            payload
                                          ].join("-"),
                                          class: {
                                            "opacity-6 text-emphasis":
                                              payload.status === null
                                          },
                                          attrs: { link: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.personaEdit(
                                                persona,
                                                payload
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("q-item-side", [
                                            _c("img", {
                                              staticClass:
                                                "brighten-50 dark-img-invert-100 on-left-sm",
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/persona." +
                                                  _vm.offerings.personas.bases[
                                                    persona
                                                  ].group +
                                                  "." +
                                                  persona +
                                                  ".svg"
                                              }
                                            })
                                          ]),
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass:
                                                    "font-size-100p capitalize",
                                                  attrs: { label: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      persona.replace(
                                                        /\_/g,
                                                        " "
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-side",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: {
                                                width: "80px",
                                                height: "60px"
                                              },
                                              attrs: { right: "" }
                                            },
                                            [
                                              _c(
                                                "transition",
                                                {
                                                  attrs: {
                                                    mode: "out-in",
                                                    appear: "",
                                                    "enter-active-class":
                                                      "animated400 " +
                                                      (payload.status === null
                                                        ? "fadeIn"
                                                        : "flipInY"),
                                                    "leave-active-class":
                                                      "animated200 " +
                                                      (payload.status === null
                                                        ? "fadeOut"
                                                        : "flipOutY")
                                                  }
                                                },
                                                [
                                                  payload.status === "health"
                                                    ? _c(
                                                        "div",
                                                        { key: "health" },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "auto",
                                                              height: "auto",
                                                              "max-width":
                                                                "40px",
                                                              "max-height":
                                                                "50px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/statics/_demo/avoid_protect.svg"
                                                            }
                                                          }),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "no-margin text-weight-bold text-protect font-size-80p"
                                                            },
                                                            [_vm._v("AVOID")]
                                                          )
                                                        ]
                                                      )
                                                    : payload.status === "avoid"
                                                    ? _c(
                                                        "div",
                                                        { key: "avoid" },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "auto",
                                                              height: "auto",
                                                              "max-width":
                                                                "40px",
                                                              "max-height":
                                                                "50px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/statics/_demo/nosign_attention.svg"
                                                            }
                                                          }),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "no-margin text-weight-bold text-attention font-size-80p"
                                                            },
                                                            [_vm._v("AVOID")]
                                                          )
                                                        ]
                                                      )
                                                    : payload.status === "good"
                                                    ? _c(
                                                        "div",
                                                        { key: "good" },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "auto",
                                                              height: "auto",
                                                              "max-width":
                                                                "40px",
                                                              "max-height":
                                                                "50px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/statics/_demo/heart_educate.svg"
                                                            }
                                                          }),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "no-margin text-weight-bold text-educate font-size-80p"
                                                            },
                                                            [_vm._v("WANT")]
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        { key: "null" },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "auto",
                                                              height: "auto",
                                                              "max-width":
                                                                "40px",
                                                              "max-height":
                                                                "40px",
                                                              opacity: "0.5"
                                                            },
                                                            attrs: {
                                                              width: "40",
                                                              src:
                                                                "/statics/_demo/ellipsis.circle.fill_tertiary.svg"
                                                            }
                                                          }),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-faded no-margin text-weight-bold text-tertiary font-size-80p"
                                                            },
                                                            [_vm._v("NOT SET")]
                                                          )
                                                        ]
                                                      )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.product.data.channel.online
                    ? _c(
                        "q-modal",
                        {
                          staticClass: "appLayer dialog-item",
                          attrs: {
                            id: "dialogDropoffScan",
                            position: "bottom"
                          },
                          model: {
                            value: _vm.dialogDropoffScanShow,
                            callback: function($$v) {
                              _vm.dialogDropoffScanShow = $$v
                            },
                            expression: "dialogDropoffScanShow"
                          }
                        },
                        [
                          _c(
                            "q-modal-layout",
                            [
                              _c(
                                "q-toolbar",
                                {
                                  directives: [
                                    {
                                      name: "touch-pan",
                                      rawName:
                                        "v-touch-pan.vertical.prevent.stop",
                                      value: _vm.modalAdapt,
                                      expression: "modalAdapt",
                                      modifiers: {
                                        vertical: true,
                                        prevent: true,
                                        stop: true
                                      }
                                    }
                                  ],
                                  staticClass: "cursor-grab",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c("q-toolbar-title", [
                                    _vm._v(_vm._s(_vm.$t("WINGLET.SCAN.L")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "q-toolbar",
                                {
                                  staticClass: "toolbar-overscroll-shadow",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "subinfo",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanFlashToggle()
                                                  _vm.soundPlay("tap_disabled")
                                                }
                                              }
                                            },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  size: "2em",
                                                  name: "ion-flash"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "subinfo",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanCameraToggle()
                                                  _vm.soundPlay("tap_disabled")
                                                }
                                              }
                                            },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  size: "2em",
                                                  name: "ion-reverse-camera"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "primary-light",
                                                "text-color": "primary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanOff()
                                                  _vm.soundPlay("tap")
                                                  _vm.soundFade(
                                                    "pinging",
                                                    0.2,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "chevron.compact.down_white.svg"
                                                      : "chevron.compact.down_primary.svg"),
                                                  height: "10"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-padding no-padding-top text-center text-family-brand"
                                },
                                [
                                  _c("q-scroll-observable", {
                                    on: {
                                      scroll: _vm.toolbarShadowOnOverscroll
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "overflow-hidden",
                                      staticStyle: {
                                        height: "50vh",
                                        border: "4px solid #3d4042",
                                        "border-radius": "1em"
                                      },
                                      attrs: { id: "wingletDecodeContainer" }
                                    },
                                    [
                                      _vm.dialogDropoffScanShow &&
                                      _vm.dialogDropoffScanStreamShow
                                        ? _c("qrcode-stream", {
                                            staticClass: "scanning",
                                            on: {
                                              decode: _vm.wingletOnDecode,
                                              init: _vm.wingletOnInit
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogQRCode", position: "bottom" },
                      model: {
                        value: _vm.dialogQRCodeShow,
                        callback: function($$v) {
                          _vm.dialogQRCodeShow = $$v
                        },
                        expression: "dialogQRCodeShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image":
                              "url(/statics/_demo/qrcode_primary.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("QRCODE.LABEL")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogQRCodeShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "layout-padding no-padding-top text-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeInUp animated-d800"
                                  }
                                },
                                [
                                  _vm.product.data.qrcode_ref
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "95%",
                                          "border-radius": "2rem",
                                          "max-width": "53vh"
                                        },
                                        attrs: {
                                          src: _vm.product.data.qrcode_ref
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          "A QR Code was not generated for this channel."
                                        )
                                      ])
                                ]
                              ),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeInUp animated-d800"
                                  }
                                },
                                [
                                  _vm.product.data.qrcode_ref
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-family-brand text-weight-semibold text-center font-size-100p text-attention",
                                          staticStyle: {
                                            "word-break": "break-all",
                                            "margin-bottom": "-20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                _vm.product.data.shortlink.replace(
                                                  "ltsbtrf.ly",
                                                  "mywin.gs"
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                          _c("q-btn", {
                                            attrs: {
                                              flat: "",
                                              round: "",
                                              icon: "ion-link",
                                              title: _vm.product.data.shortlink.replace(
                                                "ltsbtrf.ly",
                                                "mywin.gs"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.openURL(
                                                  _vm.product.data.shortlink.replace(
                                                    "ltsbtrf.ly",
                                                    "mywin.gs"
                                                  )
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogAbout", position: "bottom" },
                      model: {
                        value: _vm.dialogAboutShow,
                        callback: function($$v) {
                          _vm.dialogAboutShow = $$v
                        },
                        expression: "dialogAboutShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image": "url(/statics/_demo/info.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.ABOUT.L")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogAboutShow = false
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "layout-padding no-padding-top" },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "q-list",
                                {
                                  staticClass:
                                    "card text-family-brand full-width on-top-lg",
                                  attrs: { "no-border": "", link: "" }
                                },
                                [
                                  _c(
                                    "q-list-header",
                                    {
                                      staticClass:
                                        "font-size-120p text-secondary text-center",
                                      attrs: { "compact-left": "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Channel\n          "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "q-item",
                                    [
                                      _c(
                                        "q-item-side",
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              name: "ion-grid",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-120p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            { attrs: { label: "" } },
                                            [_vm._v("Channel ID")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-fixedwidth-brand",
                                          attrs: { right: "" }
                                        },
                                        [
                                          _c("q-item-tile", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.product.data.shortlink.split(
                                                  "/"
                                                )[3]
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("p", [_vm._v(" ")]),
                                  _vm.product.data.business.metas.description
                                    ? [
                                        _c(
                                          "q-list-header",
                                          {
                                            staticClass:
                                              "font-size-120p text-secondary text-center",
                                            attrs: { "compact-left": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Description\n            "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "q-item",
                                          [
                                            _c("q-item-main", [
                                              _c("p", {
                                                staticClass:
                                                  "text-family-brand q-title layout-padding no-padding-top text-subinfo slideInDown",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.product.data.business
                                                      .metas.description
                                                  )
                                                }
                                              })
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm.product.data.business.phone ||
                                  _vm.getWebsiteDomain(
                                    _vm.product.data.business.website
                                  )
                                    ? [
                                        _c(
                                          "q-list-header",
                                          {
                                            staticClass:
                                              "font-size-120p text-secondary text-center",
                                            attrs: { "compact-left": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Contact\n            "
                                            )
                                          ]
                                        ),
                                        _vm.product.data.business.phone
                                          ? _c(
                                              "q-item",
                                              {
                                                attrs: { link: "" },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.call(
                                                      _vm.product.data.business
                                                        .phone
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-item-side",
                                                  [
                                                    _c("q-icon", {
                                                      attrs: {
                                                        name: "ion-call",
                                                        color: _vm.anyDarkmode
                                                          ? ""
                                                          : "blue-grey-10",
                                                        size: "33px"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-main",
                                                  {
                                                    staticClass:
                                                      "font-size-120p text-weight-semibold text-system-brand"
                                                  },
                                                  [
                                                    _c(
                                                      "q-item-tile",
                                                      { attrs: { label: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.product.data
                                                              .business.phone
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.getWebsiteDomain(
                                          _vm.product.data.business.website
                                        )
                                          ? _c(
                                              "q-item",
                                              {
                                                attrs: { link: "" },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.openURL(
                                                      _vm.product.data.business
                                                        .website
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-item-side",
                                                  [
                                                    _c("q-icon", {
                                                      attrs: {
                                                        name: "ion-link",
                                                        color: _vm.anyDarkmode
                                                          ? ""
                                                          : "blue-grey-10",
                                                        size: "33px"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "q-item-main",
                                                  {
                                                    staticClass:
                                                      "text-system-brand"
                                                  },
                                                  [
                                                    _c(
                                                      "q-item-tile",
                                                      {
                                                        staticClass:
                                                          "font-size-120p text-weight-semibold",
                                                        attrs: { label: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getWebsiteDomain(
                                                              _vm.product.data
                                                                .business
                                                                .website
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "q-item-tile",
                                                      {
                                                        staticClass:
                                                          "text-fixedwidth-brand",
                                                        attrs: { sublabel: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.product.data
                                                              .business.website
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogShare", position: "bottom" },
                      model: {
                        value: _vm.dialogShareShow,
                        callback: function($$v) {
                          _vm.dialogShareShow = $$v
                        },
                        expression: "dialogShareShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image":
                              "url(/statics/_demo/square.and.arrow.up.fill_primary.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("SHARE")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogShareShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "layout-padding no-padding-top" },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "q-list",
                                {
                                  staticClass: "text-family-brand",
                                  attrs: {
                                    "no-border": "",
                                    link: "",
                                    dark: _vm.anyDarkmode
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "q-title layout-padding no-padding-top text-subinfo slideInDown",
                                      staticStyle: { "padding-bottom": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Share this channel using any of the following methods and platforms for everyone to view.\n          "
                                      )
                                    ]
                                  ),
                                  _vm.shareSheetSupport()
                                    ? _c(
                                        "q-item",
                                        {
                                          attrs: { item: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.shareSheet($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("q-item-main", {
                                            staticClass:
                                              "font-size-160p text-weight-semibold",
                                            attrs: {
                                              label: _vm.$t("SHARESHEET.LABEL")
                                            }
                                          }),
                                          _c(
                                            "q-item-side",
                                            {
                                              staticClass:
                                                "text-center on-top-sm"
                                            },
                                            [
                                              _c("img", {
                                                class: {
                                                  "filter-invert-80":
                                                    _vm.anyDarkmode
                                                },
                                                staticStyle: { height: "33px" },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/rectangle.stack.fill.svg"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: { item: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openURL(_vm.productFullURI)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", {
                                        staticClass:
                                          "font-size-160p text-weight-semibold",
                                        attrs: {
                                          label: _vm.$t("WEBLINK.LABEL")
                                        }
                                      }),
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center on-top-sm"
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "33px" },
                                            attrs: {
                                              src:
                                                "/statics/_demo/square.and.arrow.up.fill_primary.svg"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: { item: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.qrcode($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", {
                                        staticClass:
                                          "font-size-160p text-weight-semibold",
                                        attrs: { label: _vm.$t("QRCODE.LABEL") }
                                      }),
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center on-top-sm"
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "33px" },
                                            attrs: {
                                              src:
                                                "/statics/_demo/qrcode_primary.svg"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { height: "40px" } })
                ],
                2
              )
            ]
          ),
          _vm.kioskMode === false
            ? _c(
                "q-page-sticky",
                { attrs: { position: "bottom", offset: [0, 30] } },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        appear: "",
                        "enter-active-class": "animated fadeInUp animated400",
                        "leave-active-class": "animated fadeOutDown animated400"
                      }
                    },
                    [
                      !_vm.accountInitProcessing &&
                      _vm.account &&
                      _vm.account.isLoggedIn === false
                        ? _c(
                            "q-btn",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" }
                              ],
                              staticClass:
                                "shadow-24 text-weight-bold animated800 animated-c1",
                              staticStyle: {
                                height: "60px",
                                width: "80vw",
                                "max-width": "300px"
                              },
                              attrs: {
                                rounded: "",
                                size: "lg",
                                push: "",
                                color: "primary"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.loginToSubscribe()
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/heart.subscribe_white.svg",
                                  width: "33"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "on-right-sm text-system-brand"
                                },
                                [_vm._v("Login to Join")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.kioskMode === false
            ? _c(
                "q-page-sticky",
                { attrs: { position: "bottom-right", offset: [20, 30] } },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        mode: "out-in",
                        appear: "",
                        "enter-active-class": "animated fadeInUp animated400",
                        "leave-active-class": "animated fadeOutDown animated400"
                      }
                    },
                    [
                      _vm.account.subscribed !== null &&
                      _vm.account.subscribed === false &&
                      _vm.stickyButtonOrderShow
                        ? _c(
                            "q-btn",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" }
                              ],
                              key: "account_subscribe",
                              staticClass: "shadow-24",
                              attrs: {
                                loading: _vm.account.subscriptionProcess,
                                size: "lg",
                                round: "",
                                push: "",
                                color: "primary"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.subscribe()
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/statics/_demo/heart.subscribe_white.svg",
                                  width: "33"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "flex flex-center", staticStyle: { height: "100vh" } },
        [
          _c("q-spinner-puff", {
            staticClass: "loading-spinner loading-spinner-gold",
            staticStyle: { color: "#F4A724", display: "block" },
            attrs: { size: "200px" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  cfg: {},
  business: {
    stations: {
      barista: {
        staff: {
          count: 2
        },
        items: [],
        orders: {
          outstanding: 0
        },
        times: {
          wait: 5
        }
      },
      kitchen: {
        staff: {
          count: 2
        },
        items: [],
        orders: {
          outstanding: 0
        },
        times: {
          wait: 5
        }
      }
    },
    personas: {
      defaults: [
        'meat', 'poultry', 'fish', 'vegan', 'vegetarian'
      ],
      items: {
        acai_bowls: {},
        burgers: {},
        bowls: {},
        juices: {},
        shots: {},
        vegetarian: {},
        vegan: {},
        glutenfree: {},
        dairyfree: {},
        containsnut: {},
        bison: {},
        chicken: {},
        poultry: {},
        eggs: {},
        fruits: {},
        seeds: {},
        beans: {},
        legumes: {},
        nuts: {},
        fish: {},
        avocados: {},
        lemon: {},
        ginger: {},
        cayenne: {},
        apple_cider_vinegar: {},
        orange: {},
        honey: {},
        spinach: {},
        black_pepper: {},
        turmeric: {}
      },
      opposites: {
        //
        acai_bowls: [
          'bowls',
          'burgers',
          'juices',
          'shots',
          'shakes'
        ],
        burgers: [
          'acai_bowls',
          'bowls',
          'juices',
          'shots',
          'shakes'
        ],
        bowls: [
          'acai_bowls',
          'burgers',
          'juices',
          'shots',
          'shakes'
        ],
        juices: [
          'acai_bowls',
          'burgers',
          'bowls',
          'shots',
          'shakes'
        ],
        shots: [
          'acai_bowls',
          'burgers',
          'bowls',
          'juices',
          'shakes'
        ],
        shakes: [
          'acai_bowls',
          'burgers',
          'bowls',
          'juices',
          'shots'
        ],
        vegetarian: [
          'fish',
          'meat',
          'poultry',
          'vegan'
        ],
        vegan: [
          'fish',
          'meat',
          'poultry',
          'milk',
          'eggs',
          'honey',
          'spinach',
          'vegetarian'
        ],
        fish: [
          'meat',
          'poultry',
          'vegan',
          'vegetarian'
        ],
        ahi_tuna: [
          'meat',
          'poultry',
          'vegan',
          'vegetarian'
        ],
        poultry: [
          'meat',
          'fish',
          'vegan',
          'vegetarian'
        ],
        meat: [
          'fish',
          'poultry',
          'vegan',
          'vegetarian'
        ],
        honey: [
          'vegan'
        ],
        spinach: [
          'vegan',
          'vegetarian'
        ]
      },
      groups: {
        styles: {
          bases: [
            'shakes',
            'acai_bowls',
            'burgers',
            'bowls',
            'juices',
            'shots'
          ]
        },
        groups: {
          bases: [
            'meat',
            'poultry',
            'fish',
            // 'mushroom',
            // 'fruits'
            'vegetarian',
            'vegan'
          ]
        },
        labels: {
          bases: [
            'glutenfree',
            'dairyfree',
            'containsnuts',
            'cold'
          ]
        },
        practices: {
          bases: [
            'lowcarb'
            // 'lowfat'
          ]
        },
        ingredients: {
          bases: [
            'bison',
            'chicken',
            'eggs',
            'ahi_tuna',
            'salmon',
            'pineapple',
            'avocados',
            'portobello',
            'lemon',
            'ginger',
            'cayenne',
            'turmeric',
            'apple_cider_vinegar',
            'orange',
            'honey',
            'spinach',
            'black_pepper',
            'cayenne',
            'carrot',
            'beet'
          ]
        }
        // brands: {
        //   bases: ['beyond_meat']
        // },
        // safety_accessibility: {
        //   bases: ['curbside']
        // }
      }
    }
  },
  offerings: {
    personas: {
      bases: {
        // styles
        acai_bowls: {
          group: 'styles'
        },
        burgers: {
          group: 'styles'
        },
        bowls: {
          group: 'styles'
        },
        juices: {
          group: 'styles'
        },
        shots: {
          group: 'styles'
        },
        shakes: {
          group: 'styles'
        },

        // labels
        vegetarian: {
          group: 'labels'
        },
        vegan: {
          group: 'labels'
        },
        glutenfree: {
          group: 'labels'
        },
        dairyfree: {
          group: 'labels'
        },
        containsnuts: {
          group: 'labels'
        },
        cold: {
          group: 'labels'
        },

        // practices
        lowcarb: {
          group: 'practices'
        },
        lowfat: {
          group: 'practices'
        },

        // foodgroups
        meat: {
          group: 'groups'
        },
        poultry: {
          group: 'groups'
        },
        fish: {
          group: 'groups'
        },
        bread: {
          group: 'groups'
        },
        legumes: {
          group: 'groups'
        },
        nuts: {
          group: 'groups'
        },
        fruits: {
          group: 'groups'
        },
        vegetables: {
          group: 'groups'
        },
        seeds: {
          group: 'groups'
        },
        beans: {
          group: 'groups'
        },
        mushroom: {
          group: 'groups'
        },

        // ingredients
        eggs: {
          group: 'ingredients'
        },
        bison: {
          group: 'ingredients',
          ref: 'meat'
        },
        beef: {
          group: 'ingredients',
          ref: 'meat'
        },
        chicken: {
          group: 'ingredients',
          ref: 'poultry'
        },
        ahi_tuna: {
          group: 'ingredients',
          ref: 'fish'
        },
        salmon: {
          group: 'ingredients',
          ref: 'fish'
        },
        bun: {
          group: 'ingredients',
          ref: 'bread'
        },
        onions: {
          group: 'ingredients'
        },
        tomatoes: {
          group: 'ingredients'
        },
        pineapple: {
          group: 'ingredients'
        },
        portobello: {
          group: 'ingredients'
        },
        sriracha: {
          group: 'ingredients'
        },
        cilantro_lime_vinaigrette: {
          group: 'ingredients'
        },
        cheese: {
          group: 'ingredients'
        },
        lettuce: {
          group: 'ingredients'
        },
        avocados: {
          group: 'ingredients'
        },
        lemon: {
          group: 'ingredients'
        },
        ginger: {
          group: 'ingredients'
        },
        cayenne: {
          group: 'ingredients'
        },
        turmeric: {
          group: 'ingredients'
        },
        apple_cider_vinegar: {
          group: 'ingredients'
        },
        orange: {
          group: 'ingredients'
        },
        honey: {
          group: 'ingredients'
        },
        spinach: {
          group: 'ingredients'
        },
        black_pepper: {
          group: 'ingredients'
        },
        acai: {
          group: 'ingredients'
        },
        whey: {
          group: 'ingredients'
        },
        banana: {
          group: 'ingredients'
        },
        coconut: {
          group: 'ingredients'
        },
        granula: {
          group: 'ingredients'
        },
        flaxseed: {
          group: 'ingredients'
        },
        apple: {
          group: 'ingredients'
        },
        blueberry: {
          group: 'ingredients'
        },
        strawberry: {
          group: 'ingredients'
        },
        soy: {
          group: 'ingredients'
        },
        peanut: {
          group: 'ingredients'
        },
        agave: {
          group: 'ingredients'
        },
        almomds: {
          group: 'ingredients'
        },
        granola: {
          group: 'ingredients'
        },
        almond: {
          group: 'ingredients'
        },
        carrot: {
          group: 'ingredients'
        },
        beet: {
          group: 'ingredients'
        },
        broccoli: {
          group: 'ingredients'
        },
        bell_pepper: {
          group: 'ingredients'
        },
        grapefruit: {
          group: 'ingredients'
        },
        spinach: {
          group: 'ingredients'
        },
        lime: {
          group: 'ingredients'
        },
        cucumber: {
          group: 'ingredients'
        },
        pear: {
          group: 'ingredients'
        },
        celery: {
          group: 'ingredients'
        },
        parsley: {
          group: 'ingredients'
        },
        asparagus: {
          group: 'ingredients'
        },
        greenonion: {
          group: 'ingredients'
        },

        // brands
        beyond_meat: {
          group: 'brands'
        },

        // safety_accessibility
        curbside: {
          group: 'safety_accessibility'
        }
      }
    },
    groups: {
      shakes: {
        list: [
          'ms_skinny'
        ]
      },
      wraps: {},
      acai_bowls: {
        list: [
          'tropical',
          'berry_blast',
          'coconut',
          'nutty_butter'
        ]
      },
      breakfast: {},
      burgers: {
        list: [
          'burger',
          'ninja',
          'salmon',
          'portobello',
          'vegetarian',
          'vegan'
        ]
      },
      bowls: {
        list: [
          'thai_monster',
          'the_sexy'
        ]
      },
      juices: {
        description: 'Freshly made to order & cold-pressed.',
        list: [
          'dark_baby',
          // 'high_c',
          // 'lean_machine',
          'sweet_greens'
        ]
      },
      protein: {},
      coffees: {},
      fraps: {},
      dessert: {},
      shots: {
        list: [
          'burning_man',
          'titan'
        ]
      },
      pancakes: {},
      salads: {},
      build: {}
    },
    items: {
      // ## SHAKES
      // ---------------------------------
      'ms_skinny': {
        name: 'Ms Skinny',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['shakes', 'cold', 'vegetarian'],
          price: 8.50,
          size: {
            value: 24,
            unit: 'oz'
          },
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/09-Protein%20Shakes/PROTEINHOUSE_Menu_04-ProteinShakes-MsSkinny.jpg',
          nutrition: {
            calories: 395,
            macronutrients: {
              p: 26, c: 34, f: 19
            }
          },
          time: 5,
          contains: [{
            highlight: true,
            base: 'whey',
            modifiers: ['vanilla']
          }, {
            highlight: true,
            base: 'strawberry'
          }, {
            highlight: true,
            base: 'pineapple'
          }, {
            highlight: true,
            base: 'coconut',
            modifiers: ['milk']
          }]
        }]
      },

      // ---------------------------------
      'the_sexy': {
        name: 'The Sexy',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['fish', 'salmon', 'glutenfree', 'bowls'],
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/BOWLS-08-The-Sexy_3961.jpg',
          nutrition: {
            calories: 642,
            macronutrients: {
              p: 47, c: 53, f: 28
            }
          },
          time: 15,
          contains: [{
            highlight: true,
            base: 'glutenfree'
          }, {
            highlight: true,
            base: 'salmon',
            modifiers: ['grilled', 'sustainable']
          }, {
            base: 'broccoli'
          }, {
            base: 'tomatoes'
          }, {
            base: 'peppers',
            modifiers: ['red']
          }, {
            base: 'mushrooms'
          }, {
            base: 'onions',
            modifiers: ['red']
          }, {
            base: 'asparagus'
          }, {
            base: 'squash'
          }, {
            highlight: true,
            base: 'avocados'
          }, {
            base: 'zucchini'
          }, {
            highlight: true,
            base: 'spinach'
          }, {
            base: 'quinoa'
          }, {
            base: 'cilantro_lime_vinaigrette'
          }]
        }]
      },
      'thai_monster': {
        name: 'Thai Monster',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['meat', 'poultry', 'beef', 'chicken', 'glutenfree', 'containsnuts', 'bowls'],
          price: 16.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/05-Lean%20Bowls/PROTEINHOUSE_Menu_07-LeanBowls-ThaiMonsterBowl.jpg',
          nutrition: {
            calories: 733,
            macronutrients: {
              p: 80, c: 50, f: 22
            }
          },
          time: 15,
          contains: [{
            highlight: true,
            base: 'containsnuts'
          }, {
            highlight: true,
            base: 'glutenfree'
          }, {
            highlight: true,
            base: 'chicken',
            modifiers: ['grilled', 'hormonefree', 'cagefree']
          }, {
            highlight: true,
            base: 'beef',
            modifiers: ['certified_angus', 'steak']
          }, {
            base: 'broccoli'
          }, {
            base: 'asparagus'
          }, {
            base: 'greenonion'
          }, {
            base: 'peanut',
            modifiers: ['spicy', 'thai', 'sauce']
          }, {
            base: 'peanut',
            modifiers: ['chopped']
          }]
        }]
      },
      // ---------------------------------
      'lean_machine': {
        name: 'Lean Machine',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['vegetarian', 'vegan', 'glutenfree', 'dairyfree', 'juices'],
          price: 7.75,
          qty: 1,
          size: {
            value: 16,
            unit: 'oz'
          },
          photo: 'https://www.protein-house.com/images/menu/2018/10-Cold%20Press%20Juice/PROTEINHOUSE_Menu_04-ColdPressJuice-LeanMachine.jpg',
          nutrition: {
            calories: 344,
            macronutrients: {
              p: 9, c: 79, f: 2
            }
          },
          contains: [{
            highlight: true,
            base: 'carrot'
          }, {
            highlight: true,
            base: 'celery'
          }, {
            // highlight: true,
            base: 'parsley'
          }, {
            // highlight: true,
            base: 'spinach'
          }, {
            // highlight: true,
            base: 'ginger'
          }, {
            // highlight: true,
            base: 'apple'
          }]
        }]
      },
      // ---------------------------------
      'sweet_greens': {
        name: 'Sweet Greens',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['vegetarian', 'vegan', 'glutenfree', 'dairyfree', 'juices'],
          price: 12.00,
          qty: 1,
          size: {
            value: 16,
            unit: 'oz'
          },
          photo: 'https://www.protein-house.com/images/menu/2018/10-Cold%20Press%20Juice/PROTEINHOUSE_Menu_03-ColdPressJuice-SweetGreens.jpg',
          nutrition: {
            calories: 299,
            macronutrients: {
              p: 7, c: 75, f: 1
            }
          },
          time: 5,
          contains: [{
            highlight: true,
            base: 'pear'
          }, {
            base: 'lime'
          }, {
            highlight: true,
            base: 'spinach'
          }, {
            base: 'cucumber'
          }, {
            base: 'apple'
          }, {
            base: 'agave'
          }]
        }]
      },
      // ---------------------------------
      'dark_baby': {
        name: 'Dark Baby',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['vegetarian', 'vegan', 'glutenfree', 'dairyfree', 'juices'],
          price: 12.00,
          qty: 1,
          size: {
            value: 16,
            unit: 'oz'
          },
          photo: 'https://www.protein-house.com/images/menu/2018/10-Cold%20Press%20Juice/PROTEINHOUSE_Menu_01-ColdPressJuice-DarkBaby.jpg',
          nutrition: {
            calories: 233,
            macronutrients: {
              p: 5, c: 52, f: 2
            }
          },
          time: 5,
          contains: [{
            highlight: true,
            base: 'beet'
          }, {
            highlight: true,
            base: 'carrot'
          }, {
            highlight: true,
            base: 'orange'
          }, {
            mutator: true,
            base: 'size',
            modifiers: ['16oz']
          }]
        }, {
          personas: ['vegetarian', 'vegan', 'glutenfree', 'dairyfree', 'juices'],
          price: 12.00,
          qty: 1,
          size: {
            value: 24,
            unit: 'oz'
          },
          photo: 'https://www.protein-house.com/images/menu/2018/10-Cold%20Press%20Juice/PROTEINHOUSE_Menu_01-ColdPressJuice-DarkBaby.jpg',
          nutrition: {
            calories: 233,
            macronutrients: {
              p: 5, c: 52, f: 2
            }
          },
          time: 5,
          contains: [{
            highlight: true,
            base: 'beet'
          }, {
            highlight: true,
            base: 'carrot'
          }, {
            highlight: true,
            base: 'orange'
          }, {
            mutator: true,
            base: 'size',
            modifiers: ['24oz']
          }]
        }]
      },
      // ---------------------------------
      'high_c': {
        name: 'High C',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['vegetarian', 'vegan', 'glutenfree', 'dairyfree', 'juices'],
          price: 12.00,
          qty: 1,
          size: {
            value: 16,
            unit: 'oz'
          },
          photo: 'https://www.protein-house.com/images/menu/2018/10-Cold%20Press%20Juice/PROTEINHOUSE_Menu_05-ColdPressJuice-HighC.jpg',
          nutrition: {
            calories: 340,
            macronutrients: {
              p: 8, c: 81, f: 1
            }
          },
          contains: [{
            base: 'broccoli'
          }, {
            // highlight: true,
            base: 'bell_pepper',
            modifiers: ['red']
          }, {
            highlight: true,
            base: 'apple'
          }, {
            highlight: true,
            base: 'orange'
          }, {
            highlight: true,
            base: 'grapefruit'
          }]
        }]
      },
      // ---------------------------------
      'nutty_butter': {
        name: 'Nutty Butter',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'containsnuts', 'vegetarian', 'dairyfree', 'cold', 'acai_bowls'],
          price: 12,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/08-Acai%20Bowls/PROTEINHOUSE_Menu_04-AcaiBowls-AcaiNuttyButter-Bowl.jpg',
          nutrition: {
            calories: 999,
            macronutrients: {
              p: 51, c: 78, f: 55
            }
          },
          time: 5,
          contains: [{
            base: 'containsnuts',
            highlight: true
          }, {
            base: 'glutenfree',
            highlight: true
          }, {
            base: 'acai',
            modifiers: ['organic', 'unsweetend'],
            highlight: true
          }, {
            base: 'whey',
            modifiers: ['chocolate']
          }, {
            base: 'soy',
            modifiers: ['milk']
          }, {
            base: 'peanut',
            modifiers: ['butter']
          }, {
            base: 'agave'
          }, {
            base: 'almond'
          }, {
            base: 'granola'
          }, {
            base: 'flaxseed'
          }]
        }]
      },
      // ---------------------------------
      'coconut': {
        name: 'Coconut',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'vegetarian', 'dairyfree', 'cold', 'acai_bowls'],
          price: 12.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/08-Acai%20Bowls/PROTEINHOUSE_Menu_03-AcaiBowls-AcaiCoconut-Bowl.jpg',
          nutrition: {
            calories: 995,
            macronutrients: {
              p: 36, c: 96, f: 53
            }
          },
          time: 5,
          contains: [{
            base: 'glutenfree',
            highlight: true
          }, {
            base: 'acai',
            modifiers: ['organic', 'unsweetend'],
            highlight: true
          }, {
            base: 'whey',
            modifiers: ['vanilla']
          }, {
            base: 'coconut',
            modifiers: ['milk'],
            highlight: true
          }, {
            base: 'coconut',
            modifiers: ['water']
          }, {
            base: 'coconut',
            modifiers: ['flakes']
          }, {
            base: 'honey'
          }, {
            base: 'granola'
          }, {
            base: 'flaxseed'
          }]
        }]
      },
      // ---------------------------------
      'berry_blast': {
        name: 'Berry Blast',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'vegetarian', 'vegan', 'dairyfree', 'cold', 'acai_bowls'],
          price: 12.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/08-Acai%20Bowls/PROTEINHOUSE_Menu_02-AcaiBowls-BerryBlast-Bowl.jpg',
          nutrition: {
            calories: 816,
            macronutrients: {
              p: 39, c: 107, f: 27
            }
          },
          time: 5,
          contains: [{
            base: 'glutenfree',
            highlight: true
          }, {
            base: 'acai',
            modifiers: ['organic', 'unsweetend'],
            highlight: true
          }, {
            base: 'whey',
            modifiers: ['vanilla']
          }, {
            base: 'apple',
            modifiers: ['juice']
          }, {
            base: 'blueberry'
          }, {
            base: 'strawberry',
            highlight: true
          }, {
            base: 'banana'
          }, {
            base: 'granola'
          }, {
            base: 'flaxseed'
          }]
        }]
      },
      // ---------------------------------
      'tropical': {
        name: 'Tropical',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'vegetarian', 'vegan', 'dairyfree', 'cold', 'acai_bowls'],
          signature: true,
          price: 12.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/08-Acai%20Bowls/PROTEINHOUSE_Menu_01-AcaiBowls-TropicalAcai-Bowl.jpg',
          nutrition: {
            calories: 952,
            macronutrients: {
              p: 40, c: 113, f: 40
            }
          },
          time: 5,
          contains: [{
            base: 'glutenfree',
            highlight: true
          }, {
            base: 'acai',
            modifiers: ['organic', 'unsweetend'],
            highlight: true
          }, {
            base: 'whey',
            modifiers: ['vanilla']
          }, {
            base: 'pineapple',
            highlight: true
          }, {
            base: 'banana'
          }, {
            base: 'coconut',
            modifiers: ['flakes']
          }, {
            base: 'granola'
          }, {
            base: 'flaxseed'
          }]
        }]
      },
      // ---------------------------------
      'burning_man': {
        name: 'Burning Man',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'vegetarian', 'vegan', 'dairyfree', 'shots'],
          price: 5.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/14-Wellness%20Shots/PROTEINHOUSE_Menu_02-WellnessShots-BurningManShot.jpg',
          nutrition: {
            calories: 86,
            macronutrients: {
              p: 1, c: 28, f: 5
            }
          },
          time: 5,
          contains: [{
            base: 'lemon',
            highlight: true
          }, {
            base: 'ginger',
            highlight: true
          }, {
            base: 'cayenne',
            highlight: true
          }]
        }]
      },
      'titan': {
        name: 'Titan',
        flow: 'exact',
        station: 'barista',
        products: [{
          default: true,
          personas: ['glutenfree', 'vegetarian', 'dairyfree', 'shots'],
          price: 5.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/14-Wellness%20Shots/PROTEINHOUSE_Menu_01-WellnessShots-TitanShot.jpg',
          nutrition: {
            calories: 144,
            macronutrients: {
              p: 3, c: 32, f: 1
            }
          },
          time: 5,
          contains: [{
            base: 'honey',
            highlight: true
          }, {
            base: 'ginger'
          }, {
            base: 'apple_cider_vinegar'
          }, {
            base: 'orange'
          }, {
            base: 'turmeric',
            highlight: true
          }, {
            base: 'black_pepper'
          }, {
            base: 'cayenne',
            highlight: true
          }]
        }, {
          personas: ['glutenfree', 'vegetrian', 'vegan', 'dairyfree', 'shots'],
          price: 5.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/14-Wellness%20Shots/PROTEINHOUSE_Menu_01-WellnessShots-TitanShot.jpg',
          nutrition: {
            calories: 144,
            macronutrients: {
              p: 3, c: 32, f: 1
            }
          },
          time: 5,
          contains: [{
            base: 'vegan',
            highlight: true
          }, {
            mutator: true,
            base: 'agave',
            highlight: true
          }, {
            base: 'ginger'
          }, {
            base: 'apple_cider_vinegar'
          }, {
            base: 'orange'
          }, {
            base: 'turmeric',
            highlight: true
          }, {
            base: 'black_pepper'
          }, {
            base: 'cayenne',
            highlight: true
          }]
        }]
      },
      // ---------------------------------
      'vegetarian': {
        name: 'Vegetarian',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['vegetarian', 'burgers'],
          signature: true,
          price: 13.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/03-Plant%20Powered%20Protein/PROTEINHOUSE_Menu_04a-PlantPoweredProtein-SouthwestVeggieBurger-01-WholeWheatBun.jpg',
          nutrition: {
            calories: 579,
            macronutrients: {
              p: 30, c: 51, f: 30
            }
          },
          time: 10,
          contains: [{
            base: 'bun',
            modifiers: ['whole-wheet']
          }, {
            base: 'beyond_meat',
            highlight: true
          }]
        }, {
          personas: ['vegetarian', 'glutenfree', 'burgers'],
          signature: true,
          price: 14.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/03-Plant%20Powered%20Protein/PROTEINHOUSE_Menu_04b-PlantPoweredProtein-SouthwestVeggieBurger-02-GlutenFreeBun.jpg',
          nutrition: {
            calories: 624,
            macronutrients: {
              p: 18, c: 74, f: 32
            }
          },
          time: 10,
          contains: [{
            highlight: true,
            base: 'glutenfree'
          }, {
            mutator: true,
            base: 'bun',
            modifiers: ['glutenfree']
          }, {
            base: 'beyond_meat',
            highlight: true
          }]
        }]
        /*
        , {
          personas: ['vegetarian', 'dairyfree'],
          signature: true,
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/03-Plant%20Powered%20Protein/PROTEINHOUSE_Menu_04c-PlantPoweredProtein-SouthwestVeggieBurger-03-EzekielBun.jpg',
          nutrition: {
            calories: 589,
            macronutrients: {
              p: 21, c: 71, f: 28
            }
          },
          contains: [{
            mutator: true,
            base: 'bun',
            modifiers: ['dairyfree']
          }, {
            base: 'beyond_meat',
            highlight: true
          }]
        }]
        */
      },
      // ---------------------------------
      'vegan': {
        name: 'Vegan',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['vegan', 'dairyfree', 'burgers'],
          signature: true,
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/03-Plant%20Powered%20Protein/PROTEINHOUSE_Menu_04c-PlantPoweredProtein-SouthwestVeggieBurger-03-EzekielBun.jpg',
          nutrition: {
            calories: 579,
            macronutrients: {
              p: 30, c: 51, f: 30
            }
          },
          time: 10,
          contains: [{
            base: 'vegan',
            highlight: true
          }, {
            base: 'dairyfree',
            highlight: true
          }, {
            base: 'bun',
            modifiers: ['ezekiel']
          }, {
            base: 'beyond_meat',
            highlight: true
          }]
        }, {
          personas: ['vegan', 'dairyfree', 'glutenfree', 'burgers'],
          signature: true,
          price: 13.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/03-Plant%20Powered%20Protein/PROTEINHOUSE_Menu_04d-PlantPoweredProtein-SouthwestVeggieBurger-04-CollardGreen.jpg',
          nutrition: {
            calories: 450,
            macronutrients: {
              p: 15, c: 44, f: 26
            }
          },
          time: 10,
          contains: [{
            mutator: true,
            base: 'bun',
            modifiers: ['collard-green']
          }, {
            base: 'beyond_meat',
            highlight: true
          }]
        }]
      },
      // ---------------------------------
      'portobello': {
        name: 'Portobello',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['poultry', 'glutenfree', 'portobello', 'burgers'],
          price: 14.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_06-HighProteinBurgers-PortobelloSandwich.jpg',
          nutrition: {
            calories: 264,
            macronutrients: {
              p: 44, c: 13, f: 5
            }
          },
          time: 10,
          contains: [{
            highlight: true,
            base: 'glutenfree'
          }, {
            highlight: true,
            base: 'portobello',
            qty: 2,
            modifiers: ['caps']
          }, {
            highlight: true,
            base: 'chicken',
            modifiers: ['grilled', 'hormonefree', 'cagefree']
          }]
        }]
      },
      // ---------------------------------
      'salmon': {
        name: 'Salmon',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['fish', 'salmon', 'burgers'],
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_07-HighProteinBurgers-SalmonBurger-01-WholeWheatBun.jpg',
          nutrition: {
            calories: 573,
            macronutrients: {
              p: 44, c: 41, f: 26
            }
          },
          time: 15,
          contains: [{
            base: 'bun',
            modifiers: ['whole_weat']
          }, {
            base: 'salmon',
            modifiers: ['sustainable', 'grilled'],
            highlight: true
          }, {
            base: 'spinach'
          }, {
            base: 'onions'
          }, {
            base: 'avocados',
            highlight: true
          }, {
            base: 'tomatoes'
          }, {
            base: 'agave_mustard'
          }]
        }, {
          personas: ['fish', 'salmon', 'glutenfree', 'burgers'],
          price: 16.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_07-HighProteinBurgers-SalmonBurger-02-GlutenFreeBun.jpg',
          nutrition: {
            calories: 625,
            macronutrients: {
              p: 42, c: 49, f: 30
            }
          },
          time: 15,
          contains: [{
            highlight: true,
            base: 'glutenfree'
          }, {
            mutator: true,
            base: 'bun',
            modifiers: ['glutenfree']
          }, {
            base: 'salmon',
            modifiers: ['sustainable'],
            highlight: true
          }, {
            base: 'spinach'
          }, {
            base: 'onions'
          }, {
            base: 'avocados',
            highlight: true
          }, {
            base: 'tomatoes'
          }, {
            base: 'agave_mustard'
          }]
        }]
      },
      // ---------------------------------
      'ninja': {
        name: 'Ninja',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['fish', 'ahi_tuna', 'burgers'],
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_05-HighProteinBurgers-NinjaBurger-01-WholeWheatBun.jpg',
          nutrition: {
            calories: 472,
            macronutrients: {
              p: 63, c: 46, f: 4
            }
          },
          time: 15,
          contains: [{
            base: 'bun',
            modifiers: ['whole_weat']
          }, {
            base: 'ahi_tuna',
            modifiers: ['sustainable', 'seared'],
            highlight: true
          }, {
            base: 'eggs',
            qty: 2,
            modifiers: ['whites']
            // highlight: true
          }, {
            base: 'onions',
            modifiers: ['red']
          }, {
            base: 'pineapple',
            highlight: true
          }, {
            base: 'mushrooms'
          }, {
            base: 'tomatoes'
          }, {
            base: 'sriracha'
          }, {
            base: 'cilantro_lime_vinaigrette'
          }]
        }, {
          personas: ['fish', 'ahi_tuna', 'glutenfree', 'burgers'],
          price: 16.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_05-HighProteinBurgers-NinjaBurger-02-GlutenFreeBun.jpg',
          nutrition: {
            calories: 513,
            macronutrients: {
              p: 60, c: 53, f: 7
            }
          },
          time: 15,
          contains: [{
            mutator: true,
            base: 'bun',
            modifiers: ['glutenfree']
          }, {
            base: 'ahi_tuna',
            modifiers: ['sustainable', 'seared'],
            highlight: true
          }, {
            base: 'eggs',
            qty: 2,
            modifiers: ['whites'],
            highlight: true
          }, {
            base: 'onions',
            modifiers: ['red']
          }, {
            base: 'pineapple'
          }, {
            base: 'mushrooms'
          }, {
            base: 'tomatoes'
          }, {
            base: 'sriracha'
          }, {
            base: 'cilantro_lime_vinaigrette'
          }]
        }]
      },
      // ---------------------------------
      'burger': {
        name: 'Burger',
        flow: 'exact',
        station: 'kitchen',
        products: [{
          default: true,
          personas: ['bison', 'eggs', 'meat', 'burgers'],
          signature: true,
          price: 15.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_01-HighProteinBurgers-PHBurger-01-WholeWheatBun.jpg',
          nutrition: {
            calories: 498,
            macronutrients: {
              p: 55, c: 30, f: 18
            }
          },
          time: 15,
          contains: [{
            base: 'bun',
            modifiers: ['whole-wheet']
          }, {
            base: 'bison',
            qty: 1,
            highlight: true,
            modifiers: ['organic', 'grass-fed']
          }, {
            base: 'eggs',
            qty: 3,
            modifiers: ['whites'],
            highlight: true
          }, {
            base: 'cheese',
            modifiers: ['cheddar', 'low-fat']
          }, {
            base: 'lettuce'
          }, {
            base: 'tomatoes'
          }]
        }, {
          personas: ['bison', 'eggs', 'meat', 'glutenfree', 'burgers'],
          price: 16.00,
          qty: 1,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_01-HighProteinBurgers-PHBurger-02-GlutenFreeBun.jpg',
          nutrition: {
            calories: 541,
            macronutrients: {
              p: 53, c: 36, f: 21
            }
          },
          contains: [{
            base: 'glutenfree',
            highlight: true
          }, {
            mutator: true,
            base: 'bun',
            modifiers: ['glutenfree'],
            highlight: true
          }, {
            base: 'bison',
            qty: 1,
            highlight: true,
            modifiers: ['organic', 'grass-fed']
          }, {
            base: 'eggs',
            qty: 3,
            modifiers: ['whites']
          }, {
            base: 'cheese',
            qty: 1,
            modifiers: ['cheddar', 'low-fat']
          }, {
            base: 'lettuce'
          }, {
            base: 'tomatoes'
          }]
        }, {
          personas: ['bison', 'eggs', 'meat', 'dairyfree', 'burgers'],
          price: 17.00,
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_01-HighProteinBurgers-PHBurger-03-EzekielBun.jpg',
          nutrition: {
            calories: 508,
            macronutrients: {
              p: 55, c: 33, f: 17
            }
          },
          contains: [{
            mutator: true,
            base: 'bun',
            modifiers: ['ezekiel'],
            highlight: true
          }, {
            base: 'bison',
            qty: 1,
            highlight: true,
            modifiers: ['organic', 'grass-fed']
          }, {
            base: 'eggs',
            qty: 3,
            modifiers: ['whites']
          }, {
            base: 'cheese',
            qty: 1,
            modifiers: ['cheddar', 'low-fat']
          }, {
            base: 'lettuce'
          }, {
            base: 'tomatoes'
          }]
        }, {
          personas: ['bison', 'eggs', 'meat', 'dairyfree', 'burgers'],
          photo: 'https://www.protein-house.com/images/menu/2018/06-High%20Protein%20Burgers/PROTEINHOUSE_Menu_01-HighProteinBurgers-PHBurger-04-GreenCollard.jpg',
          nutrition: {
            calories: 369,
            macronutrients: {
              p: 49, c: 6, f: 16
            }
          },
          contains: [{
            mutator: true,
            base: 'bun',
            modifiers: ['collard-green']
          }, {
            base: 'bison',
            qty: 1,
            highlight: true,
            modifiers: ['organic', 'grass-fed']
          }, {
            base: 'eggs',
            qty: 3,
            modifiers: ['whites'],
            highlight: true
          }, {
            base: 'cheese',
            qty: 1,
            modifiers: ['cheddar', 'low-fat']
          }, {
            base: 'lettuce'
          }, {
            base: 'tomatoes'
          }]
        }]
      }
    }
  },
  services: {
    ORDER_TO_PICKUP: {
      color: 'text-educate',
      icon: {
        name: 'bag-plus_primary',
        size: '42px'
      }
    },
    ORDER_TO_PICKUP_CURBSIDE: {
      color: 'text-purple-l',
      icon: {
        name: 'car_primary',
        size: '42px'
      }
    },
    ORDER_TO_STAY: {
      color: 'text-empower',
      icon: {
        name: 'timer_primary',
        size: '42px'
      }
    },
    PICKUP_AN_ORDER: {
      color: 'text-red',
      icon: {
        name: 'bag_primary',
        size: '36px'
      }
    }
  },
  dropoffs: {
    'd0': {
      key: 'd0',
      type: 'winglet',
      name: 'PATIO #1',
      services: [
        'ORDER_TO_PICKUP',
        'ORDER_TO_STAY',
        'PICKUP_AN_ORDER'
      ]
    },
    'd1': {
      key: 'd1',
      type: 'winglet',
      name: 'PATIO #2',
      services: [
        'ORDER_TO_PICKUP',
        'ORDER_TO_STAY',
        'PICKUP_AN_ORDER'
      ]
    },
    'd7': {
      key: 'd7',
      type: 'stand',
      name: 'STAND #1',
      services: [
        'ORDER_TO_PICKUP',
        'PICKUP_AN_ORDER'
      ]
    },
    'd8': {
      key: 'd8',
      type: 'curbside',
      name: 'CURBSIDE #1',
      services: [
        'ORDER_TO_PICKUP_CURBSIDE',
        'PICKUP_AN_ORDER'
      ]
    }
  }
}
